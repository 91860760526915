import on from "gis3d/wf/core/On";
import { Box } from "gis3d/wf/ui/geom/Box";
import ui from "gis3d/wf/ui/style/UiStyle";
import { Modal } from "gis3d/wf/ui/widget/Modal";
import dom from "gis3d/wf/util/DomUtils";
import run from "gis3d/wf/util/RuntimeUtils";

export class Dialog extends Modal {
  public defaultTopMargin: number = 100.0;

  private _modeless: boolean = false;

  private _draggable: boolean = true;
  protected dragging: boolean = false;
  protected dragHandlers: Array<any> = [];
  protected dragLastPosition: Array<number> = [0, 0];
  protected position: Box = new Box();

  public get draggable(): boolean {
    return this._draggable;
  }

  public set draggable(draggable: boolean) {
    this._draggable = draggable;
  }

  public get modeless(): boolean {
    return this._modeless;
  }

  public set modeless(modeless: boolean) {
    this._modeless = modeless;
  }

  public setPosition(): void {
    if (this.customSize) {
      if (!this.position.w) {
        this.position.w = this.customSize.w;
      }
      if (!this.position.h) {
        this.position.h = this.customSize.h;
      }
    } else {
      if (!this.position.w) {
        this.position.w = this.size;
      }
    }

    if (this.centered) {
      this.position.y = dom.win().innerHeight / 2 - this.position.h! / 2;
    } else {
      this.position.y = this.defaultTopMargin;
    }
    this.position.x = dom.win().innerWidth / 2 - this.position.w! / 2;

    dom.setCss(this.modalDialog, "maxWidth", this.position.w + "px");
    dom.setBox(this.modalDialog, this.position);
  }

  protected resizeModal(): void {
    // do nothing
  }

  public prepareBuildOptions(): void {
    // if modeless it doesn't make sense to have a backdrop filler
    if (this.modeless === true) {
      this.backdrop = false;
    }
    super.prepareBuildOptions();
    if (this.modeless === true) {
      this.domElementOptions.classes!.push(ui.disableMouse);
    }
    this.domElementOptions.classes!.push(ui.p("Dialog"));
    if (this.draggable) {
      this.domElementOptions.classes!.push(ui.draggable);
    }
  }

  protected createHeader(): void {
    super.createHeader();

    if (this.draggable) {
      on.listen(this.modalHeader, "mousedown", run.bind(this, this.onDragStart));
    }
  }

  protected onDragStart(ev: MouseEvent): any {
    this.dragging = true;
    this.dragLastPosition[0] = ev.pageX;
    this.dragLastPosition[1] = ev.pageY;
    this.clearDragHandlers();
    this.dragHandlers.push(on.listen(dom.win(), "mousemove", run.bind(this, this.onDragMove)));
    this.dragHandlers.push(on.listen(dom.win(), "mouseup", run.bind(this, this.onDragEnd)));
  }

  protected onDragMove(downEvent: MouseEvent): any {
    let x = downEvent.pageX - this.dragLastPosition[0];
    let y = downEvent.pageY - this.dragLastPosition[1];
    this.dragLastPosition[0] = downEvent.pageX;
    this.dragLastPosition[1] = downEvent.pageY;
    this.position.x! += x;
    if (this.position.x! < -this.position.w! + 100) {
      this.position.x = -this.position.w! + 100;
    } else if (this.position.x! > dom.win().innerWidth - 100) {
      this.position.x = dom.win().innerWidth - 80;
    }

    this.position.y! += y;
    if (this.position.y! < 0) {
      this.position.y = 0;
    } else if (this.position.y! > dom.win().innerHeight - 80) {
      this.position.y = dom.win().innerHeight - 80;
    }
    dom.setBox(this.modalDialog, this.position);

    // block bubbling
    downEvent.stopPropagation();
    downEvent.preventDefault();
  }

  protected onDragEnd(ev: MouseEvent): any {
    this.dragging = false;
    this.clearDragHandlers();
  }

  protected clearDragHandlers(): void {
    let removeHandler = null;
    while ((removeHandler = this.dragHandlers.pop())) {
      removeHandler();
    }
  }

  protected destroyHeader(): void {
    if (this.modalHeader) {
      dom.remove(this.modalContent, this.modalHeader);
      this.modalHeader = null;
    }
  }
}
