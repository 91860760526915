import { Mesh, MeshLambertMaterial, Color, DoubleSide, ShapeBufferGeometry, Shape, MeshPhongMaterial, SphereBufferGeometry, MeshBasicMaterial } from "three";

export class Pointer extends Mesh {
  public constructor() {
    super();

    this.geometry = new SphereBufferGeometry(0.1, 8, 8, 8);
    this.material = new MeshBasicMaterial({
      color: new Color(0xffff00),
    });

    this.visible = false;
  }
}
