import { Scene as CityvuScene } from "gis3d/cityvu/core/three/scene/Scene";
import { Scene, Group, Object3D, DirectionalLight, Vector3, PerspectiveCamera } from "three";
import NumberUtils from "gis3d/wf/util/NumberUtils";
import { MeasureObject } from "./MeasureObject";
import { Pointer } from "./Pointer";

export class Measure3dScene extends Scene {
  private _root: Group;
  private _light!: DirectionalLight;
  private buffer = new Vector3();
  private _pointer: Pointer;

  constructor() {
    super();
    this._root = new Group();
    this.add(this.root);
    this._pointer = new Pointer();
    this.add(this.pointer);
    this.light = new DirectionalLight(0xffffff, 1);
  }

  public addMeasureObject(o: MeasureObject): void {
    this.root.add(o as Object3D);
  }

  public removeMeasureObject(o: MeasureObject): void {
    this.root.remove(o as Object3D);
  }

  public update(scene: CityvuScene): void {
    this.light.position.copy(scene.camera.position);
    scene.camera.getWorldDirection(this.light.target.position);
    this.light.target.position.add(scene.camera.position);
    this.light.target.updateMatrix();
    this.light.target.updateMatrixWorld(true);

    // update measure objects
    // compute projected radius and scale accordingly
    const fov = (scene.camera as PerspectiveCamera).fov * NumberUtils.DEG_2_RAD;
    const scaleDistance = (item: Object3D): void => {
      if (item.visible) {
        const distance = scene.camera.position.distanceTo(item.getWorldPosition(this.buffer));
        const pr = (1 / Math.tan(fov * 0.5) / distance) * (scene.size.h! * 0.5);
        const scale = 60 / pr;
        item.scale.set(scale, scale, scale);
      }
    };
    this.root.children.forEach((o3d) => {
      if (o3d instanceof MeasureObject) {
        o3d.markers.children.forEach((item) => scaleDistance(item));
        o3d.labels.children.forEach((item) => scaleDistance(item));
        o3d.edgeLabels.children.forEach((item) => scaleDistance(item));
      }
    });
    scaleDistance(this.pointer);
  }

  public get root(): Group {
    return this._root;
  }

  public get light(): DirectionalLight {
    return this._light;
  }

  public set light(value: DirectionalLight) {
    if (this._light != null) {
      this.remove(this._light);
    }
    this._light = value;
    this.add(this._light);
  }

  public get pointer(): Pointer {
    return this._pointer;
  }

  public set pointer(value: Pointer) {
    this._pointer = value;
  }
}
