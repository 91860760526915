import { Color } from "three";
import { LineGeometry } from "./LineGeometry";
import { LineMaterial } from "./LineMaterial";
import { LineSegments } from "./LineSegments";

export class Line extends LineSegments {
  public readonly isLine: boolean = true;

  public constructor(geometry?: LineGeometry, material?: LineMaterial) {
    super(geometry, material);

    if (geometry === undefined) {
      this.geometry = new LineGeometry();
    }

    if (material === undefined) {
      this.material = new LineMaterial();
      this.material.color = new Color(Math.random() * 0xffffff);
    }

    this.type = "Line";
  }
}
