import { BufferAttribute, Color, InstancedInterleavedBuffer, InterleavedBufferAttribute, Intersection, Line3, MathUtils, Matrix4, Mesh, Raycaster, Vector3, Vector4 } from "three";
import { LineMaterial } from "./LineMaterial";
import { LineSegmentsGeometry } from "./LineSegmentsGeometry";

export class LineSegments extends Mesh {
  public readonly isLineSegments: boolean = true;
  public material!: LineMaterial;
  public geometry!: LineSegmentsGeometry;

  public constructor(geometry?: LineSegmentsGeometry, material?: LineMaterial) {
    super(geometry, material);
    if (geometry === undefined) {
      this.geometry = new LineSegmentsGeometry();
    }

    if (material === undefined) {
      this.material = new LineMaterial();
      this.material.color = new Color(Math.random() * 0xffffff);
    }
    this.type = "LineSegments";
  }

  public computeLineDistances(): LineSegments {
    const start = new Vector3();
    const end = new Vector3();

    return (() => {
      const geometry = this.geometry;
      const instanceStart = geometry.attributes.instanceStart as InterleavedBufferAttribute;
      const instanceEnd = geometry.attributes.instanceEnd;
      const lineDistances = new Float32Array(2 * instanceStart.data.count);

      for (let i = 0, j = 0, l = instanceStart.data.count; i < l; i++, j += 2) {
        start.fromBufferAttribute(instanceStart, i);
        end.fromBufferAttribute(instanceEnd, i);

        lineDistances[j] = j === 0 ? 0 : lineDistances[j - 1];
        lineDistances[j + 1] = lineDistances[j] + start.distanceTo(end);
      }

      const instanceDistanceBuffer = new InstancedInterleavedBuffer(lineDistances, 2, 1); // d0, d1

      geometry.setAttribute("instanceDistanceStart", new InterleavedBufferAttribute(instanceDistanceBuffer, 1, 0)); // d0
      geometry.setAttribute("instanceDistanceEnd", new InterleavedBufferAttribute(instanceDistanceBuffer, 1, 1)); // d1

      return this;
    })();
  }

  public raycast(): Function {
    const start = new Vector4();
    const end = new Vector4();

    const ssOrigin = new Vector4();
    const ssOrigin3 = new Vector3();
    const mvMatrix = new Matrix4();
    const line = new Line3();
    const closestPoint = new Vector3();

    return (raycaster: Raycaster, intersects: Intersection[]) => {
      if (raycaster.camera === null) {
        console.error("LineSegments: Raycaster.camera needs to be set in order to raycast against LineSegments.");
      }

      const threshold = (raycaster.params as any).Line2 !== undefined ? (raycaster.params as any).threshold || 0 : 0;

      const ray = raycaster.ray;
      const camera = raycaster.camera;
      const projectionMatrix = camera.projectionMatrix;

      const geometry = this.geometry;
      const material = this.material;
      const resolution = material.resolution;
      const lineWidth = material.linewidth + threshold;

      const instanceStart = geometry.attributes.instanceStart as BufferAttribute;
      const instanceEnd = geometry.attributes.instanceEnd as BufferAttribute;

      // pick a point 1 unit out along the ray to avoid the ray origin
      // sitting at the camera origin which will cause "w" to be 0 when
      // applying the projection matrix.
      ray.at(1, (ssOrigin as unknown) as Vector3);

      // ndc space [ - 1.0, 1.0 ]
      ssOrigin.w = 1;
      ssOrigin.applyMatrix4(camera.matrixWorldInverse);
      ssOrigin.applyMatrix4(projectionMatrix);
      ssOrigin.multiplyScalar(1 / ssOrigin.w);

      // screen space
      ssOrigin.x *= resolution.x / 2;
      ssOrigin.y *= resolution.y / 2;
      ssOrigin.z = 0;

      ssOrigin3.copy((ssOrigin as unknown) as Vector3);

      const matrixWorld = this.matrixWorld;
      mvMatrix.multiplyMatrices(camera.matrixWorldInverse, matrixWorld);

      for (var i = 0, l = instanceStart.count; i < l; i++) {
        start.fromBufferAttribute(instanceStart, i);
        end.fromBufferAttribute(instanceEnd, i);

        start.w = 1;
        end.w = 1;

        // camera space
        start.applyMatrix4(mvMatrix);
        end.applyMatrix4(mvMatrix);

        // clip space
        start.applyMatrix4(projectionMatrix);
        end.applyMatrix4(projectionMatrix);

        // ndc space [ - 1.0, 1.0 ]
        start.multiplyScalar(1 / start.w);
        end.multiplyScalar(1 / end.w);

        // skip the segment if it's outside the camera near and far planes
        var isBehindCameraNear = start.z < -1 && end.z < -1;
        var isPastCameraFar = start.z > 1 && end.z > 1;
        if (isBehindCameraNear || isPastCameraFar) {
          continue;
        }

        // screen space
        start.x *= resolution.x / 2;
        start.y *= resolution.y / 2;

        end.x *= resolution.x / 2;
        end.y *= resolution.y / 2;

        // create 2d segment
        line.start.copy((start as unknown) as Vector3);
        line.start.z = 0;

        line.end.copy((end as unknown) as Vector3);
        line.end.z = 0;

        // get closest point on ray to segment
        const param = line.closestPointToPointParameter(ssOrigin3, true);
        line.at(param, closestPoint);

        // check if the intersection point is within clip space
        const zPos = MathUtils.lerp(start.z, end.z, param);
        const isInClipSpace = zPos >= -1 && zPos <= 1;

        const isInside = ssOrigin3.distanceTo(closestPoint) < lineWidth * 0.5;

        if (isInClipSpace && isInside) {
          line.start.fromBufferAttribute(instanceStart, i);
          line.end.fromBufferAttribute(instanceEnd, i);

          line.start.applyMatrix4(matrixWorld);
          line.end.applyMatrix4(matrixWorld);

          var pointOnLine = new Vector3();
          var point = new Vector3();

          ray.distanceSqToSegment(line.start, line.end, point, pointOnLine);

          intersects.push({
            point: point,
            pointOnLine: pointOnLine,
            distance: ray.origin.distanceTo(point),

            object: this,
            face: null,
            faceIndex: i,
            uv: undefined,
            uv2: null,
          } as any);
        }
      }
    };
  }
}
