import { BaseUiComponent } from "../BaseUiComponent";
import dom from "gis3d/wf/util/DomUtils";
import ui from "../style/UiStyle";
import nu from "gis3d/wf/util/NumberUtils";
import jq from "jquery";
import { title } from "process";
import { ETIME } from "constants";

export class Tab extends BaseUiComponent {
  private tabs: TabContent[] = [];
  private tabsNode!: HTMLElement;
  private contentsNode!: HTMLElement;

  public constructor() {
    super();
    this.domElementType = "div";
    this.domElementOptions = {
      classes: [ui.p("tab")],
    };
  }

  public build(): void {
    super.build();
    const nav = dom.el("nav");
    this.tabsNode = dom.el("div", {
      classes: ["nav", "nav-tabs"],
      attrs: new Map([["role", "tablist"]]),
    });
    dom.append(nav, this.tabsNode);

    this.contentsNode = dom.el("div", {
      classes: ["tab-content"],
    });
    dom.append(this.domNode!, nav);
    dom.append(this.domNode!, this.contentsNode);
  }

  public getTab(index: number): TabContent {
    return this.tabs[index];
  }

  public addTab(tabContent: TabContent, active: boolean = false): void {
    const uuid = "x" + nu.createUuid().replace(/\-/gi, "");
    const titleNode = dom.el("a", {
      classes: ["nav-link"],
      html: tabContent.title,
      attrs: new Map([
        ["role", "tab"],
        ["data-toggle", "tab"],
        ["href", "#" + uuid],
      ]),
    });
    if (active) {
      dom.addClass(titleNode, "active");
    }
    dom.append(this.tabsNode, titleNode);
    dom.on(titleNode, "click", (e) => {
      e.preventDefault();
      (jq(e.target!) as any).tab("show");
    });

    const contentNode = dom.el("div", {
      classes: ["tab-pane"],
      html: tabContent.content,
      attrs: new Map([
        ["role", "tabpanel"],
        ["id", uuid],
      ]),
    });
    if (active) {
      dom.addClasses(contentNode, ["active"]);
    }
    dom.append(this.contentsNode, contentNode);

    tabContent.titleNode = titleNode;
    tabContent.contentNode = contentNode;
    this.tabs.push(tabContent);
  }
}

export interface TabContent {
  title: string;
  content: string;
  titleNode?: HTMLElement;
  contentNode?: HTMLElement;
}
