import I18N from "gis3d/wf/i18n/I18N";
import dom from "gis3d/wf/util/DomUtils";
import { BaseUiComponent } from "../BaseUiComponent";
import ui from "../style/UiStyle";
import { ToastOptions } from "./ToastOptions";

export class Toast extends BaseUiComponent {
    private _titleArea!: HTMLElement;
    private _iconNode!: HTMLElement;
    private _titleNode!: HTMLElement;
    private _contentArea!: HTMLElement;
    private _closeNode!: HTMLElement;

    public constructor(readonly options: ToastOptions) {
        super();
        this.domElementOptions.classes = [ui.p("Toast"), ui.Display.d(ui.Display.flex), ui.Flex.column];
    }

    public prepareBuildOptions(): void {
        super.prepareBuildOptions();
        this.domElementOptions.classes!.push("toast-" + this.options.type);
        this.domElementOptions.classes!.push("toast-position-" + this.options.position);
        if (this.options.cssClass != null) {
            this.domElementOptions.classes!.concat(this.options.cssClass.split(" "));
        }
    }

    public build(): void {
        super.build();
        this.titleArea = dom.el("div", {
            classes: ["toast-titlearea", ui.Display.d(ui.Display.flex)],
        });

        if (this.options.iconClass) {
            this.iconNode = dom.el("i", {
                classes: ["toast-icon", ui.fa(), ui.fa("lg")].concat(this.options.iconClass.split(" ")),
                parent: this.titleArea,
            });
        }

        this.titleNode = dom.el("div", {
            classes: ["toast-title"],
            parent: this.titleArea,
        });
        dom.set(this.titleNode, this.options.title != null ? this.options.title : I18N.i("wf.toast.defaultTitle"));

        if (this.options.closable === true) {
            this.closeNode = dom.el("i", {
                classes: ["toast-close", ui.fa(), ui.fa("lg"), ui.fa("times")],
                parent: this.titleArea,
            });
            // TODO click event
        }

        dom.append(this.domNode!, this.titleArea);

        this.contentArea = dom.el("div", {
            classes: ["toast-contentarea", ui.Display.d(ui.Display.flex)],
        });
        dom.set(this.contentArea, this.options.message);

        dom.append(this.domNode!, this.contentArea);
    }

    public get titleArea(): HTMLElement {
        return this._titleArea;
    }

    public set titleArea(value: HTMLElement) {
        this._titleArea = value;
    }

    public get iconNode(): HTMLElement {
        return this._iconNode;
    }

    public set iconNode(value: HTMLElement) {
        this._iconNode = value;
    }

    public get titleNode(): HTMLElement {
        return this._titleNode;
    }

    public set titleNode(value: HTMLElement) {
        this._titleNode = value;
    }

    public get closeNode(): HTMLElement {
        return this._closeNode;
    }

    public get contentArea(): HTMLElement {
        return this._contentArea;
    }

    public set contentArea(value: HTMLElement) {
        this._contentArea = value;
    }

    public set closeNode(value: HTMLElement) {
        this._closeNode = value;
    }
}
