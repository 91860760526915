import MouseUtils from "gis3d/wf/util/MouseUtils";
import { Geometry, Vector3 } from "three";
import { AppEvent } from "../../event/AppEvent";
import { ClickData } from "../../event/ClickData";
import { DragData } from "../../event/DragData";
import { InputManagerEvent } from "../../event/InputManager";
import { Measure } from "../../measure/Measure";
import { MeasurementUtils } from "../../measure/MeasurementUtils";
import { MeasureObject } from "../objects/MeasureObject";
import { MeasurePair } from "../objects/MeasurePair";
import { Line } from "../objects/lines/Line";
import { TextSprite } from "../objects/TextSprite";
import { SortedPickResults } from "../scene/picker/SortedPickResults";
import { Scene } from "../scene/Scene";
import ThreeUtils from "../ThreeUtils";
import { EventedMeasurementTool } from "./EventedMeasurementTool";
import { LineGeometry } from "../objects/lines/LineGeometry";

export class MultiStepMeasurementTool<T extends Measure> extends EventedMeasurementTool {
  private _isMeasuring: boolean = false;
  private _currentMeasurePair: MeasurePair<T> | null = null;
  private _showEdgeLabels: boolean = true;
  private _showLabel: boolean = false;
  private _closeLoop: boolean = false;

  private _isDraggingMarker: boolean = false;
  private _draggedMeasurePair: MeasurePair<T> | null = null;
  private _draggedMarkerIndex: number = 0;
  private _minCoordsNumber: number = 2;
  private _maxCoordsNumber: number = -1;
  private _labelCentroid: Vector3 = new Vector3();

  private updBuffer: Vector3 = new Vector3();

  public constructor(scene?: Scene) {
    super(scene);
  }

  protected attachEvents(): void {
    super.attachEvents();
    this.addEventListener(this.drag, InputManagerEvent.DRAG);
    this.addEventListener(this.drop, InputManagerEvent.DROP);
    this.addEventListener(this.click, InputManagerEvent.CLICK);
  }

  public get isMeasuring(): boolean {
    return this._isMeasuring;
  }

  public set isMeasuring(value: boolean) {
    this._isMeasuring = value;
  }

  public get currentMeasurePair(): MeasurePair<T> | null {
    return this._currentMeasurePair;
  }

  public set currentMeasurePair(value: MeasurePair<T> | null) {
    this._currentMeasurePair = value;
  }

  public get showLabel(): boolean {
    return this._showLabel;
  }

  public set showLabel(value: boolean) {
    this._showLabel = value;
  }

  public get showEdgeLabels(): boolean {
    return this._showEdgeLabels;
  }

  public set showEdgeLabels(value: boolean) {
    this._showEdgeLabels = value;
  }

  public get isDraggingMarker(): boolean {
    return this._isDraggingMarker;
  }

  public set isDraggingMarker(value: boolean) {
    this._isDraggingMarker = value;
  }

  public get draggedMeasurePair(): MeasurePair<T> | null {
    return this._draggedMeasurePair;
  }

  public set draggedMeasurePair(value: MeasurePair<T> | null) {
    this._draggedMeasurePair = value;
  }

  public get draggedMarkerIndex(): number {
    return this._draggedMarkerIndex;
  }

  public set draggedMarkerIndex(value: number) {
    this._draggedMarkerIndex = value;
  }

  public get closeLoop(): boolean {
    return this._closeLoop;
  }

  public set closeLoop(value: boolean) {
    this._closeLoop = value;
  }

  public get minCoordsNumber(): number {
    return this._minCoordsNumber;
  }

  public set minCoordsNumber(value: number) {
    this._minCoordsNumber = value;
  }

  public get labelPosition(): Vector3 {
    return this._labelCentroid;
  }

  public set labelPosition(value: Vector3) {
    this._labelCentroid = value;
  }

  public get maxCoordsNumber(): number {
    return this._maxCoordsNumber;
  }

  public set maxCoordsNumber(value: number) {
    this._maxCoordsNumber = value;
  }

  public drop(appEvent: AppEvent): void {
    super.drop(appEvent);
    this.isDraggingMarker = false;
    this.draggedMeasurePair = null;
    this.unhoverMarker();
  }

  protected mouseMove(appEvent: AppEvent): void {
    if (this.isDraggingMarker == false) {
      super.mouseMove(appEvent);
    }
  }

  public drag(appEvent: AppEvent): boolean {
    super.drag(appEvent);
    const dragData = appEvent.value as DragData;
    if (dragData.isStart && this.hoveredMarker != null) {
      this.isDraggingMarker = true;
      // LOOKUP
      const markers = this.hoveredMarker.parent!;
      const measureId = ((markers.parent as unknown) as MeasureObject).measureId;
      this.draggedMarkerIndex = markers.children.indexOf(this.hoveredMarker);

      this.draggedMeasurePair = {
        measure: this.broker.get(this.getMeasureType()).filter((m) => m.id == measureId)[0] as T,
        measureObject: (markers.parent as unknown) as MeasureObject,
      };
    } else if (this.isDraggingMarker == true) {
      this.pickRequest.normalizedCoords = ThreeUtils.normalize2dCoordinates(dragData.end, this.scene.size.w!, this.scene.size.h!);
      const results = this.picker.pick(this.pickRequest);
      const hit = new SortedPickResults(results).first();
      if (hit && this.draggedMeasurePair) {
        this.scene.crs.pointToCoords(hit.intersection.point, this.draggedMeasurePair.measure.coords[this.draggedMarkerIndex]);
        this.computeMeasurement(this.draggedMeasurePair);
        this.updateMeasureObject(this.draggedMeasurePair);
        this.broker.measuresList.render();
      }
    }
    return this.isDraggingMarker == false;
  }

  public click(appEvent: AppEvent): void {
    const clickData = appEvent.value as ClickData;

    if (MouseUtils.isLeft(clickData.buttons)) {
      if (this.hoveredMarker == null) {
        this.pickRequest.normalizedCoords = ThreeUtils.normalize2dCoordinates(clickData.point, this.scene.size.w!, this.scene.size.h!);
        const results = this.picker.pick(this.pickRequest);
        const hit = new SortedPickResults(results).first();
        if (hit) {
          if (!this.isMeasuring) {
            this.startMeasuring();
          }
          const pair = this.currentMeasurePair!;
          // ADD COORD
          pair.measure.coords.push(this.scene.crs.pointToCoords(hit.intersection.point, new Vector3()));
          // ADD MARKER
          const marker = pair.measureObject.marker();
          pair.measureObject.markers.add(marker);

          const pointsCount = pair.measureObject.markers.children.length;
          if (pointsCount > 1) {
            // ADD LINE
            const edge = pair.measureObject.edge();
            pair.measureObject.edges.add(edge);

            if (this.showEdgeLabels) {
              // ADD EDGE LABELS
              const edgeLabel = pair.measureObject.edgelabel();
              pair.measureObject.edgeLabels.add(edgeLabel);
            }

            // ADD CLOSED LOOP EDGE AND LABEL
            if (this.closeLoop && pointsCount > pair.measureObject.edges.children.length) {
              const edge = pair.measureObject.edge();
              pair.measureObject.edges.add(edge);

              if (this.showEdgeLabels) {
                const edgeLabel = pair.measureObject.edgelabel();
                pair.measureObject.edgeLabels.add(edgeLabel);
              }
            }
          }
          this.computeMeasurement(this.currentMeasurePair!);
          this.updateMeasureObject(this.currentMeasurePair!);

          if (this.maxCoordsNumber != -1 && pair.measure.valid && pointsCount == this.maxCoordsNumber) {
            this.stopMeasuring();
          }
        }
      }
    } else if (MouseUtils.isRight(clickData.buttons)) {
      if (this.isMeasuring) {
        // STOP
        this.stopMeasuring();
      }
    }
  }

  protected startMeasuring(): void {
    this.currentMeasurePair = this.createMeasure<T>();
    this.currentMeasurePair.measure.coords = [];
    this.measures3dScene.addMeasureObject(this.currentMeasurePair!.measureObject);
    this.isMeasuring = true;
    this.hoverEnabled = false;
  }

  public updateMeasureObject(pair: MeasurePair<T>): void {
    const m = pair.measure;
    const o = pair.measureObject;
    // update markers
    const nMarkers = o.markers.children.length;
    for (let index = 0; index < nMarkers; index++) {
      const marker = o.markers.children[index];
      this.scene.crs.coordsToPoint(m.coords[index], marker.position);
    }

    // update edges
    const referencePosition = new Vector3();
    if (nMarkers > 0) {
      referencePosition.copy(o.markers.children[0].position);
    }
    const nEdges = o.edges.children.length;
    for (let index = 0; index < nEdges; index++) {
      const edge = o.edges.children[index] as Line;
      edge.position.copy(referencePosition);
      const geo = edge.geometry as LineGeometry;

      this.updBuffer.subVectors(o.markers.children[index].position, referencePosition);
      const pos = [this.updBuffer.x, this.updBuffer.y, this.updBuffer.z];
      this.updBuffer.subVectors(o.markers.children[(index + 1) % nMarkers].position, referencePosition);
      pos.push(...[this.updBuffer.x, this.updBuffer.y, this.updBuffer.z]);
      geo.setPositions(pos);
      edge.computeLineDistances();
    }

    // update edge labels
    this.updateEdgeLabels(pair);

    if (m.valid && this.showLabel) {
      this.updateLabelPosition(pair);
      if (o.labels.children.length == 0) {
        pair.measureObject.labels.add(pair.measureObject.label());
      }
      const label = o.labels.children[0] as TextSprite;
      label.text = m.value.toFixed(2);
      label.position.copy(this.labelPosition);
    }
  }

  protected updateLabelPosition(pair: MeasurePair<T>) {
    const o = pair.measureObject;
    o.markers.children.reduce((acc, m) => acc.add(m.position), this.labelPosition.set(0, 0, 0));
    this.labelPosition.divideScalar(o.markers.children.length);
  }

  protected updateEdgeLabels(pair: MeasurePair<T>): void {
    const m = pair.measure;
    const o = pair.measureObject;
    const nMarkers = o.markers.children.length;
    for (let index = 0; index < o.edgeLabels.children.length; index++) {
      const label = o.edgeLabels.children[index] as TextSprite;
      label.position.lerpVectors(o.markers.children[index].position, o.markers.children[(index + 1) % nMarkers].position, 0.5);
      label.text = MeasurementUtils.distance(m.coords[index], m.coords[(index + 1) % nMarkers]).toFixed(2);
    }
  }

  protected stopMeasuring(): void {
    this.isMeasuring = false;
    this.hoverEnabled = true;
    if (this.currentMeasurePair != null) {
      const m = this.currentMeasurePair.measure;
      if (m.valid) {
        // measure
        this.computeMeasurement(this.currentMeasurePair!);
        this.updateMeasureObject(this.currentMeasurePair!);
        this.broker.add(m);
      } else {
        this.measures3dScene.removeMeasureObject(this.currentMeasurePair!.measureObject);
      }
    }
    this.currentMeasurePair = null;
  }

  protected computeMeasurement(pair: MeasurePair<T>): void {
    const m = pair.measure;
    m.valid = m.coords.length >= this.minCoordsNumber;
  }

  public disable(): void {
    this.stopMeasuring();
    super.disable();
  }
}
