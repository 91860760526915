import { Vector3, Vector2, Intersection, Plane } from "three";
import { AnimatedControls } from "./AnimatedControls";
import { AppEvent } from "../../event/AppEvent";
import { DragData } from "../../event/DragData";
import { InputManagerEvent } from "../../event/InputManager";

import ThreeUtils from "../ThreeUtils";
import MouseUtils from "gis3d/wf/util/MouseUtils";
import { WheelData } from "../../event/WheelData";
import { ExtendedIntersection, Picker } from "../scene/picker/Picker";
import { PickRequest } from "../scene/picker/PickRequest";
import { Scene } from "../scene/Scene";
import { SortedPickResults } from "../scene/picker/SortedPickResults";

export class EarthControls extends AnimatedControls {
  private _target!: Vector3 | null;
  private rotationPivot: Vector3 | null = null;

  public strifeSpeed: number = 2.8;
  public movementSpeed: number = 9.2;
  public rotationSpeed: number = 0.4;
  public walkingElevationLock: boolean = false;
  public frictionAmount: number = 13.0;
  public wheelMultiplier: number = 5.0;
  public minRadius: number = -1;
  public maxRadius: number = -1;
  public rayLimit: number = 50.0;
  public zoomDistance: number = 10.0;

  protected picker: Picker;
  protected pickRequest: PickRequest;
  protected lastRayDistanceFromPlane: number = 0;
  protected dragTargetDirection: Vector3 = new Vector3();

  protected panDelta: Vector3 = new Vector3(); // XYZ
  protected rotationDelta: Vector3 = new Vector3(); // as yaw, pitch, roll
  protected worldDelta: Vector3 = new Vector3();
  protected userInput: boolean = false;
  protected buffer: Vector3 = new Vector3();
  protected poseWasAnimated: boolean = false;

  protected isShiftDown: boolean = false;
  protected isCtrlDown: boolean = false;

  public constructor(scene?: Scene) {
    super(scene);
    this.picker = new Picker();
    this.pickRequest = new PickRequest();
  }

  public onSceneChange(scene: Scene): void {
    this.pickRequest.scene = scene;
    this.poseWasAnimated = this.scene.pose.animate;
    this.scene.pose.animate = false;
  }

  public enable(): void {
    this.stop();
    this.attachKeyboardEvents();
    this.attachMouseEvents();
    if (this.scene != null) {
      this.onSceneChange(this.scene);
    }
  }

  public disable(): void {
    this.removeEventListeners();
    if (this.scene != null) {
      this.scene.pose.animate = this.poseWasAnimated;
    }
  }

  protected attachMouseEvents() {
    this.addEventListener(this.drag, InputManagerEvent.DRAG);
    this.addEventListener(this.drop, InputManagerEvent.DROP);
    this.addEventListener(this.wheel, InputManagerEvent.MOUSEWHEEL);
    this.addEventListener(this.doubleClick, InputManagerEvent.DOUBLECLICK);
  }

  protected attachKeyboardEvents() {
    this.addEventListener(this.keyDown, InputManagerEvent.KEYDOWN);
    this.addEventListener(this.keyUp, InputManagerEvent.KEYUP);
  }

  public stop(): void {
    super.stop();
    this.rotationDelta.set(0, 0, 0);
    this.panDelta.set(0, 0, 0);
    this.worldDelta.set(0, 0, 0);
  }

  public update(delta: number): void {
    if (this.scene == null) {
      return;
    }

    // remove automations if user input has been detected
    if (this.userInput) {
      this.clearTweens();
      this.userInput = false;
    }

    const normalizedDelta = Math.min(delta, 1.0);
    const pitchStep = this.rotationDelta.y * normalizedDelta;
    const yawStep = this.rotationDelta.x * normalizedDelta;

    // rotation
    if (this.rotationPivot != null) {
      // limit to minDistance
      const radius = this.scene.pose.position.distanceTo(this.rotationPivot);
      if (this.minRadius != -1 && this.panDelta.y > 0 && radius < this.minRadius) {
        this.panDelta.y = 0;
      }
      if (this.maxRadius != -1 && this.panDelta.y < 0 && radius > this.maxRadius) {
        this.panDelta.y = 0;
      }

      // rotate on the sphere
      if (pitchStep != 0 || yawStep != 0) {
        this.scene.pose.orbitSphere(this.rotationPivot, pitchStep, yawStep);
      }
    } else {
      // rotate pose
      this.scene.pose.yaw -= yawStep;
      this.scene.pose.pitch -= pitchStep;
    }

    // pan
    this.buffer.copy(this.panDelta).multiplyScalar(normalizedDelta);
    this.scene.pose.pan3(this.buffer);

    // translate
    this.buffer.copy(this.worldDelta).multiplyScalar(normalizedDelta);
    this.scene.pose.translate(this.buffer);

    // apply friction
    const friction = Math.max(1 - this.frictionAmount * normalizedDelta, 0);
    this.panDelta.multiplyScalar(friction);
    this.rotationDelta.multiplyScalar(friction);
    this.worldDelta.multiplyScalar(friction);
  }

  protected calculatePivot(mouse: Vector2): Vector3 | null {
    const w = this.scene.size.w!;
    const h = this.scene.size.h!;
    this.pickRequest.normalizedCoords = ThreeUtils.normalize2dCoordinates(new Vector2(mouse.x, mouse.y), w, h, true);
    const results = this.picker.pick(this.pickRequest);
    const hit = new SortedPickResults(results).first();
    if (hit != null) {
      const ints = hit.intersection as ExtendedIntersection;
      const hitPoint = ints.point;
      return hitPoint.clone();
    }
    return null;
  }

  protected calculateRotationPivot(): Vector3 | null {
    const v = new Vector3(0, 0, 0.5).unproject(this.scene.camera);
    const o = new Vector3(0, 0, 0).unproject(this.scene.camera);
    return v.sub(o).normalize().multiplyScalar(30).add(this.scene.camera.position);
  }

  protected doubleClick(appEvent: AppEvent): void {
    const clickData = appEvent.value as Vector2;
    if (clickData != null) {
      const clickPosition = this.calculatePivot(clickData);
      if (clickPosition != null) {
        this.scene.pose.zoomTo(clickPosition, this.zoomDistance);
      }
    }
  }

  protected wheel(appEvent: AppEvent): void {
    const data = appEvent.value as WheelData;
    // move forward or backward
    this.panDelta.y += data.delta * this.movementSpeed * this.wheelMultiplier;
  }

  protected drag(appEvent: AppEvent): void {
    this.userInput = true;
    const dragData = appEvent.value as DragData;

    if (dragData.isStart) {
      this.target = this.calculatePivot(dragData.start);
      this.rotationPivot = this.calculateRotationPivot();

      const w = this.scene.size.w!;
      const h = this.scene.size.h!;
      const ray = ThreeUtils.ray(dragData.end, this.scene.camera, w, h);
      this.dragTargetDirection.copy(ray.direction);
    } else {
      if (this.isCtrlDown) {
        this.worldDelta.z = dragData.delta.y * this.strifeSpeed;
        this.panDelta.x = dragData.delta.x * this.strifeSpeed;
      } else if (MouseUtils.isLeft(dragData.buttons)) {
        if (this.isShiftDown && this.rotationPivot != null) {
          // rotate around pivoted point
          // x yaw, y pitch, z roll
          this.rotationDelta.x = dragData.delta.x * this.rotationSpeed;
          this.rotationDelta.y = dragData.delta.y * this.rotationSpeed;
          this.rotationDelta.z = 0;
        } else {
          const deltaDistance = dragData.delta.y;
          const deltaStrife = -dragData.delta.x;

          this.buffer
            .copy(this.dragTargetDirection)
            .multiplyScalar(deltaDistance * this.movementSpeed)
            .setZ(0);
          this.worldDelta.copy(this.buffer);
          this.panDelta.x = deltaStrife * this.strifeSpeed;
        }
      }
    }
  }

  protected drop(appEvent: AppEvent): void {
    this.userInput = true;
    this.target = null;
    this.rotationPivot = null;
  }

  protected keyUp(appEvent: AppEvent): void {
    this.userInput = true;
    const event = (appEvent as AppEvent<KeyboardEvent>).value!;
    this.isShiftDown = event.shiftKey;
    this.isCtrlDown = event.ctrlKey;
  }

  protected keyDown(appEvent: AppEvent): void {
    this.userInput = true;
    const event = (appEvent as AppEvent<KeyboardEvent>).value!;
    this.isShiftDown = event.shiftKey;
    this.isCtrlDown = event.ctrlKey;
  }

  protected get canvas(): HTMLCanvasElement | undefined {
    return this.scene.engine?.renderer.domElement;
  }

  public get target(): Vector3 | null {
    return this._target;
  }

  public set target(value: Vector3 | null) {
    this._target = value;
  }
}
