import dom from "gis3d/wf/util/DomUtils";
import ui from "gis3d/wf/ui/style/UiStyle";
import run from "gis3d/wf/util/RuntimeUtils";

import { UiContainer } from "gis3d/wf/ui/UiContainer";
import { Button } from "gis3d/wf/ui/widget/Button";
import bs from "scss/bootstrap.scss";

export enum ButtonGroupMode {
    NORMAL,
    RADIO,
    RADIO_UNSETTABLE
}

export class ButtonGroup extends UiContainer {
    private _mode: ButtonGroupMode = ButtonGroupMode.NORMAL;
    private _currentIndex: number = -1;
    public onToggle = (button: Button, idx: number, oldIdx: number): void => { };

    constructor(mode: ButtonGroupMode = ButtonGroupMode.NORMAL) {
        super();
        this._mode = mode;
        this.domElementOptions = {
            classes: [ui.Button.buttonGroup],
            attrs: new Map([["role", "group"]])
        };
    }

    public addChild(button: Button, idx: number | null = null): void {
        super.addChild(button, idx);
        run.before(button, "onClick", () => this.onButtonClick(button));
    }

    public onButtonClick(button: Button) {
        if (this.mode == ButtonGroupMode.RADIO || this.mode == ButtonGroupMode.RADIO_UNSETTABLE) {
            this.toggle(button);
        }
    }

    protected find(button: Button): number {
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i] === button) {
                return i;
            }
        }
        return -1;
    }

    public findIdentifier(identifier: string): Button | null {
        for (const b of this.children) {
            if (b.identifier == identifier) {
                return b as Button;
            }
        }
        return null;
    }

    public displayButton(button: Button, display: boolean = true): void {
        if (!display) {
            dom.remove(this.domNode!, button.domNode!);
        } else {
            if (!button.isAttached()) {
                const idx = this.find(button);
                // find first other attached button
                // before
                for (let i = idx - 1; i >= 0; i--) {
                    if (this.children[i].isAttached()) {
                        dom.after(this.children[i].domNode!, button.domNode!);
                        break;
                    }
                }
                // still not attached, after
                if (!button.isAttached()) {
                    for (let i = idx + 1; i < this.children.length; i++) {
                        if (this.children[i].isAttached()) {
                            dom.before(this.children[i].domNode!, button.domNode!);
                            break;
                        }
                    }
                }
                // no children attached, append
                if (!button.isAttached()) {
                    dom.append(this.domNode!, button.domNode!);
                }
            }
        }
    }

    public toggle(button: Button, fireEvent:boolean = true): void {
        const oldIdx = this._currentIndex;
        let idx = this.find(button);
        let unsetAll = false;
        if (this.mode == ButtonGroupMode.RADIO_UNSETTABLE && idx == oldIdx) {
            unsetAll = true;
            idx = -1;
        }

        for (let child of this.children) {
            if (unsetAll || child !== button) {
                (child as Button).active = false;
            } else {
                (child as Button).active = true;
            }
        }

        this._currentIndex = idx;
        if (fireEvent === true) {
            this.onToggle(button, idx, oldIdx);
        }
    }

    public get mode(): ButtonGroupMode {
        return this._mode;
    }

    public get currentIndex(): number {
        return this._currentIndex;
    }

    public reset() : void {
        if (this.mode == ButtonGroupMode.RADIO_UNSETTABLE) {
            this.children.forEach(c => {
                (c as Button).active = false; 
            });
            this._currentIndex = -1;
        }
    }
}
