import { EventedControls } from "./EventedControls";
import { Scene } from "../scene/Scene";
import * as TWEEN from "@tweenjs/tween.js";

export class AnimatedControls extends EventedControls {
    private _tweens!: Array<TWEEN.Tween<any>>;

    public constructor(scene?: Scene) {
        super(scene);
        this.tweens = [];
    }

    protected clearTweens(): void {
        if (this.tweens.length > 0) {
            this.tweens.forEach(e => e.stop());
            this.tweens = [];
        }
    }

    protected removeTween(t: TWEEN.Tween<any>): void {
        this.tweens = this.tweens.filter(e => e !== t);
    }

    public get tweens(): Array<any> {
        return this._tweens;
    }

    public set tweens(value: Array<any>) {
        this._tweens = value;
    }

}
