import * as TWEEN from "@tweenjs/tween.js";
import { ControlsManager } from "gis3d/cityvu/core/three/controls/ControlsManager";
import { EngineOptions } from "gis3d/cityvu/core/three/EngineOptions";
import { MeasurementToolsManager } from "gis3d/cityvu/core/three/measure/MeasurementToolsManager";
import { Scene } from "gis3d/cityvu/core/three/scene/Scene";
import { BaseUiComponent } from "gis3d/wf/ui/BaseUiComponent";
import { Box } from "gis3d/wf/ui/geom/Box";
import { Size } from "gis3d/wf/ui/geom/Size";
import ui from "gis3d/wf/ui/style/UiStyle";
import dom from "gis3d/wf/util/DomUtils";
import NumberUtils from "gis3d/wf/util/NumberUtils";
import run from "gis3d/wf/util/RuntimeUtils";
import { Stats } from "gis3d/wf/util/stats/Stats";
import { Camera, Clock, Fog, PCFShadowMap, Vector3, WebGL1Renderer, WebGLRendererParameters } from "three";
import { CloudEngine } from "./cloud/CloudEngine";
import { Cityvu3DScene } from "./scene/Cityvu3DScene";

export interface CityvuWebGLRendererParameters extends WebGLRendererParameters {
    powerPreference: string;
}

declare global {
    export interface WebGLRenderingContext {
        createVertexArray: any;
        bindVertexArray: any;
    }
}

let self: Engine;
export class Engine extends BaseUiComponent {
    protected stopRequested: boolean = false;
    protected requestAnimationHandle?: number;

    protected _options: EngineOptions;
    protected _clock!: Clock;
    protected _controlsManager!: ControlsManager;
    protected _measurementToolsManager!: MeasurementToolsManager;
    protected _renderer!: WebGL1Renderer;
    protected _cloudEngine!: CloudEngine;

    protected _scene!: Scene;

    public loopFunc: FrameRequestCallback;
    public guiUpdateFunction = (): void => {};
    protected guiUpdateHandler?: number;
    public guiUpdateInterval: number = 70;
    public stats: Stats | null = null;

    constructor(opts: EngineOptions | null = null) {
        super();
        const defaultOptions = new EngineOptions();
        if (opts) {
            this._options = run.extend(defaultOptions, opts!) as EngineOptions;
        } else {
            this._options = defaultOptions;
        }
        self = this;
        this.loopFunc = this.loop;
    }

    public get options(): EngineOptions {
        return this._options;
    }

    public get renderer(): WebGL1Renderer {
        return this._renderer;
    }

    public get camera(): Camera {
        return this.scene.camera;
    }

    public get scene(): Scene {
        return this._scene;
    }

    public set scene(scene: Scene) {
        if (this.options.fog && scene.scene3d.fog == null) {
            scene.scene3d.fog = new Fog(this.options.fogColor, 0, this.options.cameraFar - 500);
        }

        this.cloudEngine.clear();

        this._scene = scene;
        this.scene.init();
        this.updateCurrentScene();
        this.scene.engine = this;
        // orientation defaults
        this.scene.pose.pitch = 0;
        this.scene.pose.yaw = 0;
        this.scene.pose.roll = 0;
        this.controlsManager.onSceneChange(this.scene);
        this.measurementToolsManager.onSceneChange(this.scene);

        if (this.scene instanceof Cityvu3DScene) {
            const ssLocation = new Vector3();
            const c3dScene = this.scene as Cityvu3DScene;
            const location = new Vector3(...c3dScene.definition.settings.location);
            this.scene.crs.coordsToPoint(location, ssLocation);
            this.scene.pose.position.copy(ssLocation);
            if (c3dScene.definition.settings.lookAt != null) {
                const lookAt = new Vector3(...c3dScene.definition.settings.lookAt);
                const ssLookAt = new Vector3();
                const ssDirection = new Vector3();
                this.scene.crs.coordsToPoint(lookAt, ssLookAt);
                ssDirection.subVectors(ssLocation, ssLookAt).normalize();
                if (ssDirection.length() >= NumberUtils.EPSILON1) {
                    this.scene.pose.pitch = Math.asin(-ssDirection.z);
                    this.scene.pose.yaw = Math.atan2(ssDirection.x, -ssDirection.y);
                }
            }
        }
        this.scene.setInitialCameraPosition();
    }

    public get clock(): Clock {
        return this._clock;
    }

    public get controlsManager(): ControlsManager {
        return this._controlsManager;
    }

    public get measurementToolsManager(): MeasurementToolsManager {
        return this._measurementToolsManager;
    }

    public init(): void {
        this.initRenderer();

        this._clock = new Clock(true);
        this._measurementToolsManager = new MeasurementToolsManager();
        this._controlsManager = new ControlsManager();

        this.initCloudEngine();

        super.init();
    }

    public initCloudEngine(): void {
        this._cloudEngine = new CloudEngine();
    }

    public build() {
        this._domNode = this.renderer.domElement;
        dom.addClass(this.domNode!, ui.p("CityvuEngine"));
    }

    public initRenderer(): void {
        this._renderer = new WebGL1Renderer({
            alpha: this.options.alpha,
            antialias: this.options.antialias,
            logarithmicDepthBuffer: this.options.logarithmicDepthBuffer,
            premultipliedAlpha: this.options.premultipliedAlpha,
            powerPreference: this.options.powerPreference,
        } as CityvuWebGLRendererParameters);

        // init extensions
        const gl = this.renderer.context;
        gl.getExtension("EXT_frag_depth");
        gl.getExtension("WEBGL_depth_texture");
        gl.getExtension("OES_texture_float");

        const extVAO = gl.getExtension("OES_vertex_array_object");
        if (!extVAO) {
            throw new Error("OES_vertex_array_object extension not supported");
        }
        // polyfill extensions into WebGLRenderingContext (as in WebGL2)
        gl.createVertexArray = extVAO.createVertexArrayOES.bind(extVAO);
        gl.bindVertexArray = extVAO.bindVertexArrayOES.bind(extVAO);

        // configure
        this.renderer.autoClear = false;
        this.renderer.sortObjects = this.options.sortObjects;
        this.renderer.outputEncoding = this.options.gammaOutput;
        this.renderer.shadowMap.enabled = this.options.shadowMap;
        this.renderer.shadowMap.type = PCFShadowMap;
        this.renderer.setPixelRatio(this.options.pixelRatio);
        this.renderer.setClearColor(this.options.clearColor, this.options.clearAlpha);
    }

    public startup(): void {
        if (this.isStarted()) {
            return;
        }
        this.started = true;
        this.resize();
    }

    protected updateCurrentScene(uSize?: Size): void {
        let sz = uSize ? uSize : dom.marginBox(this.domNode!);
        const aspect = sz.w! / sz.h!;
        const vFov = (Math.atan(Math.tan(((this.options.cameraHorizontalFov / 2) * Math.PI) / 180) / aspect) * 2 * 180) / Math.PI;
        if (this.scene) {
            this.scene.updateCamera(aspect, vFov, this.options.cameraNear, this.options.cameraFar);
            this.scene.size = sz;
        }
    }

    public resize(box?: Box | null): Size | null {
        let sz = super.resize(box)!;
        this._renderer.setSize(sz.w!, sz.h!);
        this.updateCurrentScene();
        return sz;
    }

    public loop(domTimeStamp?: DOMHighResTimeStamp): void {
        if (!self.stopRequested) {
            const delta = self._clock.getDelta();
            TWEEN.update(domTimeStamp);
            self.scene.updateScene(delta);
            self.controlsManager.update(delta);
            self.measurementToolsManager.update(delta);
            self.cloudEngine.update(self.scene.camera, self.renderer);
            // clear rendering (whole)
            self.renderer.clear();
            self.scene.onBeforeRender(self.renderer);
            // render scene
            self._renderer.render(self.scene.scene3d, self.scene.camera);
            // render measures above
            self._renderer.render(self.measurementToolsManager.measures3dScene, self.scene.camera);
            if (self.stats !== null) {
                self.stats.update();
            }
            self.requestAnimationHandle = window.requestAnimationFrame(self.loopFunc);
        }
    }

    public start(): void {
        if (this.scene && this.camera) {
            this.stopRequested = false;
            if (self.stats !== null) {
                self.stats.begin();
            }
            this.loop();
            this.guiUpdateHandler = window.setInterval(this.guiUpdateFunction, this.guiUpdateInterval);
        } else {
            console.error("Scene or Camera not set. Not starting.");
        }
    }

    public stop(): void {
        this.stopRequested = true;
        if (this.requestAnimationHandle !== undefined) {
            window.cancelAnimationFrame(this.requestAnimationHandle);
        }
        if (this.guiUpdateHandler !== undefined) {
            window.clearInterval(this.guiUpdateHandler);
        }
    }

    public get cloudEngine(): CloudEngine {
        return this._cloudEngine;
    }
}
