import { Dialog } from "gis3d/wf/ui/widget/Dialog";
import I18N from "gis3d/wf/i18n/I18N";
import { ModalSize } from "gis3d/wf/ui/widget/Modal";
import { Tab } from "gis3d/wf/ui/widget/Tab";
export class InfoDialog extends Dialog {
  private tab!: Tab;

  public constructor() {
    super();
    this.hasFooter = false;

    this.draggable = false;
    this.centered = false;
    this.hasCloseButton = true;
    this.size = ModalSize.Large;
    this.title = I18N.i("cityvu.gui.info");
  }

  public startup(): void {
    super.startup();
    this.tab = new Tab();
    this.tab.init();

    this.tab.addTab(
      {
        title: I18N.i("cityvu.gui.info.help"),
        content: require("assets/html/" + I18N.language + "/help.html").default,
      },
      true,
    );
    this.tab.addTab({
      title: I18N.i("cityvu.gui.info.credits"),
      content: require("assets/html/" + I18N.language + "/credits.html").default,
    });
    this.addChild(this.tab);
  }
}
