import { Vector3 } from "three";
import { MeasureType } from "./MeasureType";
import NumberUtils from "gis3d/wf/util/NumberUtils";

export interface Measure {
    id: string;
    label?: string;
    type: MeasureType;
    coords: Array<Vector3>;
    adjustedCoords: Array<Vector3>;
    value: number;
    valid: boolean;
}

export interface DataMeasure<T> extends Measure {
    data: T;
}

export class ConcreteMeasure implements Measure {
    private _id!: string;
    private _label?: string;
    private _type!: MeasureType;
    private _coords!: Vector3[];
    private _adjustedCoords!: Vector3[];
    private _value!: number;
    private _valid: boolean = false;

    constructor(id?: string) {
        this.id = id || NumberUtils.createUuid();
    }

    public get label(): string {
        return this._label!;
    }

    public set label(value: string) {
        this._label = value;
    }

    public get id(): string {
        return this._id;
    }

    public set id(value: string) {
        this._id = value;
    }

    public get coords(): Vector3[] {
        return this._coords;
    }

    public set coords(value: Vector3[]) {
        this._coords = value;
    }

    public get value(): number {
        return this._value;
    }

    public set value(value: number) {
        this._value = value;
    }

    public get type(): MeasureType {
        return this._type;
    }

    public set type(value: MeasureType) {
        this._type = value;
    }

    public get valid(): boolean {
        return this._valid;
    }

    public set valid(value: boolean) {
        this._valid = value;
    }
    
    public get adjustedCoords(): Vector3[] {
        return this._adjustedCoords;
    }
    
    public set adjustedCoords(value: Vector3[]) {
        this._adjustedCoords = value;
    }
}

export class ConcreteDataMeasure<T> extends ConcreteMeasure implements DataMeasure<T> {
    private _data!: T;

    public get data(): T {
        return this._data;
    }

    public set data(value: T) {
        this._data = value;
    }
}
