import { ExtendedIntersection } from "./Picker";
import { PickResults } from "./PickResults";

export type PickResultsSorter = (a: SortedPickResult, b: SortedPickResult) => number;

export interface SortedPickResult {
    intersection: ExtendedIntersection;
    layer3dId: string;
}

export class SortedPickResults {
    private static readonly DefaultPickResultSorter = (a: SortedPickResult, b: SortedPickResult): number => {
        return a.intersection.distance - b.intersection.distance;
    };

    private _sorter: PickResultsSorter = SortedPickResults.DefaultPickResultSorter;

    public constructor(readonly source: PickResults) {}

    public get sorter(): PickResultsSorter {
        return this._sorter;
    }

    public set sorter(value: PickResultsSorter) {
        this._sorter = value;
    }

    public sort(): Array<SortedPickResult> {
        const results = new Array<SortedPickResult>();
        this.source.objects.forEach((intersections, layerId) => {
            const ic = intersections.length;
            for (let i = 0; i < ic; ++i) {
                results.push({
                    intersection: intersections[i],
                    layer3dId: layerId,
                });
            }
        });
        results.sort(this.sorter);
        return results;
    }

    public first(): SortedPickResult | null {
        const data = this.sort();
        return data && data.length > 0 ? data.shift()! : null;
    }

    public last(): SortedPickResult | null {
        const data = this.sort();
        return data && data.length > 0 ? data.pop()! : null;
    }
}
