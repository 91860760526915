import {
    Box3,
    BufferAttribute,
    BufferGeometry,
    Camera,
    Geometry,
    LinearFilter,
    Material,
    Matrix4,
    NearestFilter,
    NoBlending,
    Object3D,
    PerspectiveCamera,
    Points,
    Ray,
    RGBAFormat,
    Scene,
    Sphere,
    Vector3,
    Vector4,
    WebGLProgram,
    WebGLRenderer,
    WebGLRenderTarget,
} from "three";
import { DEFAULT_MIN_NODE_PIXEL_SIZE, COLOR_BLACK } from "./constants";
import { ClipMode, PointCloudMaterial, PointColorType, PointSizeType } from "./materials";
import { PointCloudOctreeGeometry } from "./point-cloud-octree-geometry";
import { PointCloudOctreeGeometryNode } from "./point-cloud-octree-geometry-node";
import { PickParams, PointCloudOctreePicker } from "./point-cloud-octree-picker";
import { PointCloudOctreeNode } from "./point-cloud-octree-node";
import { PointCloudTree } from "./point-cloud-tree";
import { IPointCloudTreeNode, IPotree, PickPoint } from "./types";
import { computeTransformedBoundingBox } from "./utils/bounds";
import NumberUtils from "gis3d/wf/util/NumberUtils";


export class PointCloudOctree extends PointCloudTree {
    public potree: IPotree;
    public disposed: boolean = false;
    public pcoGeometry: PointCloudOctreeGeometry;
    public boundingBox: Box3;
    public boundingSphere: Sphere;
    public material: PointCloudMaterial;
    public level: number = 0;
    public maxLevel: number = Infinity;
    public minNodePixelSize: number = DEFAULT_MIN_NODE_PIXEL_SIZE;
    public root: IPointCloudTreeNode | null = null;
    public boundingBoxNodes: Object3D[] = [];
    public visibleNodes: PointCloudOctreeNode[] = [];
    public visibleGeometry: PointCloudOctreeGeometryNode[] = [];
    public numVisiblePoints: number = 0;
    public showBoundingBox: boolean = false;
    private visibleBounds: Box3 = new Box3();
    private picker: PointCloudOctreePicker | undefined;

    public constructor(potree: IPotree, pcoGeometry: PointCloudOctreeGeometry, material?: PointCloudMaterial) {
        super();

        this.name = "";
        this.potree = potree;
        this.root = pcoGeometry.root;
        this.pcoGeometry = pcoGeometry;
        this.boundingBox = pcoGeometry.boundingBox;
        this.boundingSphere = this.boundingBox.getBoundingSphere(new Sphere());

        this.position.copy(pcoGeometry.offset);
        this.updateMatrix();

        this.material = material || new PointCloudMaterial();
        this.initMaterial(this.material);
    }

    private initMaterial(material: PointCloudMaterial): void {
        this.updateMatrixWorld(true);

        const { min, max } = computeTransformedBoundingBox(this.pcoGeometry.tightBoundingBox || this.getBoundingBoxWorld(), this.matrixWorld);

        const bWidth = max.z - min.z;
        material.heightMin = min.z - 0.2 * bWidth;
        material.heightMax = max.z + 0.2 * bWidth;
    }

    public dispose(): void {
        if (this.root) {
            this.root.dispose();
        }

        this.pcoGeometry.root.traverse(n => this.potree.lru.remove(n));
        this.pcoGeometry.dispose();
        this.material.dispose();

        this.visibleNodes = [];
        this.visibleGeometry = [];

        if (this.picker) {
          this.picker.dispose();
          this.picker = undefined;
        }

        this.disposed = true;
    }

    public get pointSizeType(): PointSizeType {
        return this.material.pointSizeType;
    }

    public set pointSizeType(value: PointSizeType) {
        this.material.pointSizeType = value;
    }

    public toTreeNode(geometryNode: PointCloudOctreeGeometryNode, parent?: PointCloudOctreeNode | null): PointCloudOctreeNode {
        const points = new Points(geometryNode.geometry, this.material);
        const node = new PointCloudOctreeNode(geometryNode, points);
        points.name = geometryNode.name;
        points.position.copy(geometryNode.boundingBox.min);
        points.frustumCulled = false;
        points.onBeforeRender = PointCloudMaterial.makeOnBeforeRender(this, node);

        if (parent) {
            parent.sceneNode.add(points);
            parent.children[geometryNode.index] = node;

            geometryNode.oneTimeDisposeHandlers.push(() => {
                node.disposeSceneNode();
                // Replace the tree node (rendered and in the GPU) with the geometry node.
                parent.children[geometryNode.index] = geometryNode;
            });
        } else {
            this.root = node;
            this.add(points);
        }

        return node;
    }

    public updateVisibleBounds() {
        const bounds = this.visibleBounds;
        bounds.min.set(Infinity, Infinity, Infinity);
        bounds.max.set(-Infinity, -Infinity, -Infinity);

        for (let idx = 0; idx < this.visibleNodes.length; idx++) {
            const node = this.visibleNodes[idx];

            if (node.isLeafNode) {
                bounds.expandByPoint(node.boundingBox.min);
                bounds.expandByPoint(node.boundingBox.max);
            }
        }
    }

    public updateBoundingBoxes(): void {
        if (!this.showBoundingBox || !this.parent) {
            return;
        }

        let bbRoot: any = this.parent.getObjectByName("bbroot");
        if (!bbRoot) {
            bbRoot = new Object3D();
            bbRoot.name = "bbroot";
            this.parent.add(bbRoot);
        }

        const visibleBoxes = [];
        for (const node of this.visibleNodes) {
            if (node.boundingBoxNode !== undefined && node.isLeafNode) {
                visibleBoxes.push(node.boundingBoxNode);
            }
        }

        bbRoot.children = visibleBoxes;
    }

    public updateMatrixWorld(force: boolean): void {
        if (this.matrixAutoUpdate === true) {
            this.updateMatrix();
        }

        if (this.matrixWorldNeedsUpdate === true || force === true) {
            if (!this.parent) {
                this.matrixWorld.copy(this.matrix);
            } else {
                this.matrixWorld.multiplyMatrices(this.parent.matrixWorld, this.matrix);
            }

            this.matrixWorldNeedsUpdate = false;

            force = true;
        }
    }

    public hideDescendants(object: Object3D): void {
        const toHide: Object3D[] = [];
        for (const child of object.children) {
            if (child.visible) {
                toHide.push(child);
            }
        }

        while (toHide.length > 0) {
            const objToHide = toHide.shift()!;
            objToHide.visible = false;
            for (const child of objToHide.children) {
                if (child.visible) {
                    toHide.push(child);
                }
            }
        }
    }

    public moveToOrigin(): void {
        //this.position.set(0, 0, 0); // Reset, then the matrix will be updated in getBoundingBoxWorld()
        this.position.set(0, 0, 0).sub(this.getBoundingBoxWorld().getCenter(new Vector3()));
    }

    public moveToGroundPlane(): void {
        this.position.y += -this.getBoundingBoxWorld().min.y;
    }

    public getBoundingBoxWorld(): Box3 {
        this.updateMatrixWorld(true);
        return computeTransformedBoundingBox(this.boundingBox, this.matrixWorld);
    }

    public getVisibleExtent() {
        return this.visibleBounds.applyMatrix4(this.matrixWorld);
    }

    public pick(renderer: WebGLRenderer, camera: Camera, ray: Ray, params: Partial<PickParams> = {}): PickPoint | null {
        this.picker = this.picker || new PointCloudOctreePicker();
        return this.picker.pick(renderer, camera, ray, [this], params);
    }

    public get progress() {
        return this.visibleGeometry.length === 0 ? 0 : this.visibleNodes.length / this.visibleGeometry.length;
    }
}
