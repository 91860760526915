import { MeasureType } from "../../measure/MeasureType";
import { MultiStepMeasurementTool } from "./MultiStepMeasurementTool";
import { AngleMeasure } from "../../measure/AngleMeasure";
import { Scene } from "../scene/Scene";
import I18N from "gis3d/wf/i18n/I18N";
import { MeasureObject } from "../objects/MeasureObject";
import { MeasurePair } from "../objects/MeasurePair";
import { MeasurementUtils } from "../../measure/MeasurementUtils";
import { TextSprite } from "../objects/TextSprite";
import NumberUtils from "gis3d/wf/util/NumberUtils";

export class AngleTool extends MultiStepMeasurementTool<AngleMeasure> {
  public constructor(scene?: Scene) {
    super(scene);
    this.message = I18N.i("cityvu.core.measurementTool.angleTool");
    this.closeLoop = false;
    this.minCoordsNumber = 3;
    this.maxCoordsNumber = 3;
    this.showLabel = true;
    this.showEdgeLabels = false;
  }

  protected configureMeasureObject(mobj: MeasureObject): MeasureObject {
    mobj.markerColor = 0x004444;
    mobj.edgeColor = 0x00ffff;
    return mobj;
  }

  public getMeasureType(): MeasureType {
    return MeasureType.ANGLE;
  }

  protected updateEdgeLabels(pair: MeasurePair<AngleMeasure>): void {}

  protected updateLabelPosition(pair: MeasurePair<AngleMeasure>) {
    const o = pair.measureObject;
    this.labelPosition.copy(o.markers.children[1].position);
  }

  protected computeMeasurement(pair: MeasurePair<AngleMeasure>): void {
    super.computeMeasurement(pair);
    const m = pair.measure;
    if (m.valid === true) {
      m.value = NumberUtils.RAD_2_DEG * MeasurementUtils.angle(m.coords[1], m.coords[0], m.coords[2]);
    }
  }
}
