import { CityvuOptions } from "gis3d/cityvu/CityvuOptions";
import { FpsControls } from "gis3d/cityvu/core/three/controls/FpsControls";
import { Engine } from "gis3d/cityvu/core/three/Engine";
import { Scene } from "gis3d/cityvu/core/three/scene/Scene";
import { WaitingScene } from "gis3d/cityvu/core/three/scene/WaitingScene";
import { CityvuGui } from "gis3d/cityvu/gui/CityvuGui";
import { SceneDefinition } from "gis3d/cityvu/scene/definition/SceneDefinition";
import { SceneDefinitionParser } from "gis3d/cityvu/scene/definition/SceneDefinitionParser";
import i18n from "gis3d/wf/i18n/I18N";
import { Xhr, XhrRequest} from "gis3d/wf/net/Xhr";
import { Button } from "gis3d/wf/ui/widget/Button";
import DomUtils from "gis3d/wf/util/DomUtils";
import { Stats } from "gis3d/wf/util/stats/Stats";
import { InputManager } from "./core/event/InputManager";
import { AngleMeasure } from "./core/measure/AngleMeasure";
import { AreaMeasure } from "./core/measure/AreaMeasure";
import { InfoMeasure } from "./core/measure/InfoMeasure";
import { LengthMeasure } from "./core/measure/LengthMeasure";
import { Measure } from "./core/measure/Measure";
import { MeasuresBroker } from "./core/measure/MeasuresBroker";
import { MeasureType } from "./core/measure/MeasureType";
import { ConstrainedControls } from "./core/three/controls/ConstrainedControls";
import { ControlsType } from "./core/three/controls/ControlsType";
import { EarthControls } from "./core/three/controls/EarthControls";
import { OrbitControls } from "./core/three/controls/OrbitControls";
import { AngleTool } from "./core/three/measure/AngleTool";
import { AreaTool } from "./core/three/measure/AreaTool";
import { InfoTool } from "./core/three/measure/InfoTool";
import { LineTool } from "./core/three/measure/LineTool";
import { Cityvu3DScene } from "./core/three/scene/Cityvu3DScene";
import { Cityvu3DSceneFactory } from "./core/three/scene/Cityvu3DSceneFactory";
import { LayerTreeNode, LayerTreeStore } from "./core/three/scene/LayerTreeStore";
import { Pose } from "./core/three/scene/Pose";
import { GuiOptionsUtil } from "./gui/GuiOptionsUtil";
import { RtcChannel } from "./rtc/RtcChannel";
import { MeasureUtils } from "./core/measure/MeasureUtils";
import { RtcActions } from "./rtc/RtcActions";
import { ToastOptions } from "gis3d/wf/ui/toast/ToastOptions";
import { ToastPosition } from "gis3d/wf/ui/toast/ToastPosition";
import Topic from "gis3d/wf/core/Topic";
import { Toaster } from "gis3d/wf/ui/toast/Toaster";
import { ToastType } from "gis3d/wf/ui/toast/ToastType";
import TokenManager from "./core/util/TokenManager";

export class Cityvu {
    protected _gui!: CityvuGui;
    protected _engine!: Engine;
    protected _inputManager!: InputManager;
    protected _waitingScene!: WaitingScene;
    protected _dataChannel?: RtcChannel;

    public constructor(readonly options: CityvuOptions) {}

    protected initDataChannel(): void {
        const opts = this.options.dataChannel;
        if (opts.enabled) {
            this._dataChannel = new RtcChannel(opts, this);
            this._dataChannel.open();
            // enable sending
            this.engine.measurementToolsManager.enableSend = opts.canSendMeasure;
            this.engine.measurementToolsManager.onSend = (m: Measure) => {
                if (this.dataChannel.isConnected()) {
                    this.dataChannel.send(RtcActions.MEASUREMENT, {
                        measure: MeasureUtils.prepareToShare(m),
                    });
                } else {
                    const toastOpts = {} as ToastOptions;
                    toastOpts.title = "Cityvu";
                    toastOpts.type = ToastType.ERROR;
                    toastOpts.iconClass = "fas fa-exclamation-triangle";
                    toastOpts.message = i18n.i("cityvu.gui.measurement.sendToCarto");
                    toastOpts.autoHide = true;
                    toastOpts.position = ToastPosition.TOP_RIGHT;
                    toastOpts.closable = true;

                    Topic.publish(Toaster.SHOW, toastOpts);
                }
            };
        }
    }

    protected initEngine(): void {
        this._engine = new Engine();
        this.engine.init();
        const statOpts = this.options.stats;
        if (statOpts != null && statOpts.enabled) {
            this.engine.stats = new Stats(statOpts.options, statOpts.showPanel);
            if (statOpts.showPanel) {
                const statsPanel = this.engine.stats.panel!;
                DomUtils.append(DomUtils.body(), statsPanel.domNode!);
                statsPanel.startup();
            }
        }

        this._inputManager = new InputManager();
        this.inputManager.mouseTarget = this.engine.domNode;
        this.inputManager.mouseUpTarget = DomUtils.body();
        this.engine.controlsManager.inputManager = this.inputManager;
        this.engine.measurementToolsManager.inputManager = this.inputManager;
    }

    protected initControls(): void {
        const cm = this.engine.controlsManager;

        const earth = new EarthControls();
        cm.add(ControlsType.EARTH, earth);

        const orbit = new OrbitControls();
        cm.add(ControlsType.ORBIT, orbit);

        const fps = new FpsControls();
        cm.add(ControlsType.FPS, fps);

        const constrained = new ConstrainedControls();
        cm.add(ControlsType.CONSTRAINED, constrained);

        this.gui.callbacks.onControlsToggle = (button: Button, idx: number, oldIdx: number) => {
            cm.select(button.identifier!);
        };
    }

    protected initMeasurementTools(): void {
        const m = this.engine.measurementToolsManager;

        // initialize broker
        const b = new MeasuresBroker();
        b.messageBar = this.gui.messageBar;
        b.measuresList = this.gui.measuresList;
        b.inputManager = this.inputManager;
        b.factory.register(MeasureType.INFO, InfoMeasure);
        b.factory.register(MeasureType.ANGLE, AngleMeasure);
        b.factory.register(MeasureType.AREA, AreaMeasure);
        b.factory.register(MeasureType.LENGTH, LengthMeasure);
        // TODO DATACHANNEL b.dataChannel = this.dataChannel;
        m.measuresBroker = b;

        const area = new AreaTool();
        m.add(MeasureType.AREA, area);

        const angle = new AngleTool();
        m.add(MeasureType.ANGLE, angle);

        const line = new LineTool();
        m.add(MeasureType.LENGTH, line);

        const info = new InfoTool();
        m.add(MeasureType.INFO, info);

        this.gui.callbacks.onMeasurementsToggle = (button: Button, idx: number, oldIdx: number) => {
            if (idx === -1) {
                m.unselect();
            } else {
                m.select(button.identifier!);
            }
        };

        m.enableCopy = this.options.canCopyMeasure;
    }

    public init(): Promise<void> {
        this.initEngine();

        this._gui = new CityvuGui();
        let promise = new Promise<void>((resolve, reject) => {
            this.gui.init();
            this.initMeasurementTools();
            this.initControls();
            this.gui.mainPane.resizeChildren = true;
            this.gui.mainPane.addChild(this.engine);
            this.gui.startup();
            this.gui.options = GuiOptionsUtil.clone(this.options.gui);

            this.gui.callbacks.onLayersClick = () => {
                this.gui.layersWidget.displayed = !this.gui.layersWidget.displayed;
                this.gui.layersButton.active = this.gui.layersWidget.displayed;
            };

            this.gui.callbacks.onLayerCheck = (node: LayerTreeNode) => {
                if (node.layerId != null) {
                    // if layerId != null, than we are in a Cityvu3DScene
                    // so we have SceneDefinition and LayerManager available
                    const cityvuScene = this.scene as Cityvu3DScene;
                    // is node.layerSourceId != null?
                    // 1 yes -> we are in an option, turn off old layer3d and turn on the new one
                    // 2 no ->
                    // 2.1 has this layer multiple sources?
                    // 2.1.1 yes -> turn off all layer3ds but remember which option was selected
                    // 2.1.2 no -> switch associated layer3d off
                    if (node.layerId != null) {
                        if (node.layerSourceId != null) {
                            cityvuScene.layerManager.selectSource(node.layerId, node.layerSourceId);
                        } else {
                            cityvuScene.layerManager.setVisibility(node.layerId, node.checked);
                        }
                    }
                } else if (node.layer3dId != null) {
                    const layer = this.scene.findLayer(node.layer3dId);
                    if (layer != undefined) {
                        layer.visible = !layer.visible;
                    }
                }
            };

            this.gui.callbacks.onUserSettingDialogHide = () => {
                this.inputManager.enable();
            };

            this.gui.callbacks.onUserSettingDialogShow = () => {
                this.inputManager.disable();
            };

            this.engine.guiUpdateFunction = () => this.guiUpdateFunction();

            this.initDataChannel();

            this.inputManager.enable();

            resolve();
        });
        return promise;
    }

    protected guiUpdateFunction(): void {
        if (this.gui.compass) {
            const a = this.scene.frustum.cameraAngle();
            this.gui.compass.angle = a;
        }
    }

    //
    // PUBLIC APIs
    //

    public start(): void {
        this.engine.start();
    }

    public stop(): void {
        this.engine.stop();
    }

    public get gui(): CityvuGui {
        return this._gui;
    }

    public get engine(): Engine {
        return this._engine;
    }

    public get scene(): Scene {
        return this.engine.scene;
    }

    public set scene(scene: Scene) {
        this.gui.show = scene.showGui;
        if (scene.guiOptions != null) {
            this.gui.options = GuiOptionsUtil.merge(scene.guiOptions, this.options.gui);
        } else {
            this.gui.options = GuiOptionsUtil.clone(this.options.gui);
        }
        this.engine.scene = scene;

        this.gui.layersWidget.store = LayerTreeStore.create(scene);
        // attach callback
        this.engine.scene.onLayersChange = () => {
            this.gui.layersWidget.store = LayerTreeStore.create(this.engine.scene);
        };
    }

    public get dataChannel(): RtcChannel {
        return this._dataChannel!;
    }

    public reset(showWaitingScreen: boolean = false): void {
        if (this.gui.overlayArea) {
            this.overlayMessage = null;
        }

        if (this.gui.measuresButtonGroup != null) {
            this.gui.measuresButtonGroup.reset();
        }

        if (this.engine.measurementToolsManager != null) {
            this.engine.measurementToolsManager.measuresBroker.clear();
        }

        if (showWaitingScreen) {
            if (this._waitingScene == null) {
                this._waitingScene = new WaitingScene();
            }
            this.scene = this._waitingScene;
        }
    }

    public load(sceneDefinition: SceneDefinition): boolean {
        this.overlayMessage = i18n.i("cityvu.core.loadingScene");
        let scene = null;
        try {
            scene = Cityvu3DSceneFactory.get(sceneDefinition);
        } finally {
            if (scene != null) {
                this.overlayMessage = null;
                this.scene = scene;
                return true;
            } else {
                this.overlayMessage = i18n.i("cityvu.error.loadingScene");
            }
        }
        return false;
    }

    public loadFromString(sceneDefinitionString: string): boolean {
        const sceneDef = SceneDefinitionParser.parse(sceneDefinitionString);
        if (sceneDef != null) {
            return this.load(sceneDef);
        } else {
            this.overlayMessage = i18n.i("cityvu.error.sceneDefinition");
        }
        return false;
    }

    public loadFromUrl(sceneDefinitionUrl: string): Promise<any> {
        // transform using dom (relative links and so on)
        const aNode = DomUtils.el("a") as HTMLLinkElement;
        aNode.href = sceneDefinitionUrl;
        const realUrl = aNode.href;
        this.reset(true);
        this.overlayMessage = i18n.i("cityvu.core.loadingSceneDefinition");

        let request : XhrRequest = {
            url: realUrl
        };

        if (TokenManager.token) {
            request.headers = { "Authorization": "Basic " + TokenManager.getBearer(TokenManager.token) };
        }
        
        return Xhr.request(request).then(
            data => {
                const sceneDef = SceneDefinitionParser.parse(data);
                if (sceneDef != null) {
                    return this.load(sceneDef);
                } else {
                    this.overlayMessage = i18n.i("cityvu.error.sceneDefinition");
                }
                return false;
            },
            error => {
                this.overlayMessage = i18n.i("cityvu.error.loadingSceneDefinition");
            },
        );
    }

    public set pose(pose: Pose) {
        this.scene.pose = pose;
    }

    public get pose(): Pose {
        return this.scene.pose;
    }

    public get inputManager(): InputManager {
        return this._inputManager;
    }

    public startMeasuring(type: MeasureType): void {
        if (this.engine.measurementToolsManager.get(type) != null) {
            this.engine.measurementToolsManager.select(type);
            const button = this.gui.measuresButtonGroup.findIdentifier(type);
            if (button && !button.active) {
                this.gui.measuresButtonGroup.toggle(button, false);
            }
        }
    }

    public getMeasures(type: MeasureType): Array<Measure> {
        return this.engine.measurementToolsManager.measuresBroker.collector.get(type);
    }

    public stopMeasuring(): void {
        this.engine.measurementToolsManager.unselect();
        this.gui.measuresButtonGroup.reset();
    }

    public selectControl(control: ControlsType): void {
        if (this.engine.controlsManager.get(control) != null) {
            this.engine.controlsManager.select(control);
            const button = this.gui.controlsButtonGroup.findIdentifier(control);
            if (button && !button.active) {
                this.gui.controlsButtonGroup.toggle(button, false);
            }
        }
    }

    public set overlayMessage(textOrHtml: string | null) {
        const oa = this.gui.overlayArea;
        if (oa) {
            if (textOrHtml) {
                oa.displayed = true;
                oa.content = textOrHtml;
            } else {
                oa.displayed = false;
                oa.content = "";
            }
        }
    }
}
