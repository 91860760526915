import { Group, Mesh, Material, MeshLambertMaterial, Color, Object3D, Font, SphereBufferGeometry, BufferGeometry, MeshBasicMaterial } from "three";
import { TextSprite } from "./TextSprite";
import { MeasureType } from "../../measure/MeasureType";
import { LineGeometry } from "./lines/LineGeometry";
import { Line } from "./lines/Line";
import { LineMaterial } from "./lines/LineMaterial";

export class MeasureObject extends Group {
  public static TEXT_FONT: Font | undefined;

  private _highlighted: boolean = false;
  private _markerColor: number = 0xff0000;
  private _edgeColor: number = 0xff0000;
  private _ambientColor: number = 0xaaaaaa;
  private _emissiveColor: number = 0x222222;
  private _lineWidth: number = 0.005;
  private _markerGeometry: BufferGeometry = new SphereBufferGeometry(0.125, 10, 10);
  private _highlightColor: number = 0xffff00;

  private _edgeLabelTextColor: number = 0x111111;
  private _edgeLabelBackground: number = 0xcccccc;
  private _textColor: number = 0xffffff;
  private _labelBackground: number = 0x0a0a0a;
  protected _markers: Group = new Group();
  protected _labels: Group = new Group();
  protected _edges: Group = new Group();
  protected _edgeLabels: Group = new Group();
  private _measureId: string;
  private _measureType: MeasureType;

  public constructor(measureId: string, measureType: MeasureType) {
    super();
    this.add(this.labels);
    this.add(this.edgeLabels);
    this.add(this.edges);
    this.add(this.markers);
    this._measureId = measureId;
    this._measureType = measureType;
  }

  public clearObject(item?: Object3D): void {
    const scope = item || this;
    for (var i = scope.children.length - 1; i >= 0; i--) {
      const item = scope.children[i];
      if (item.children.length > 0) {
        item.children.forEach((i) => {
          this.clearObject(i);
        });
      }
      if (item instanceof Mesh && item.material) {
        if (Material instanceof Array) {
          for (const m of item.material as Material[]) {
            m.dispose();
          }
        } else {
          (item.material as Material).dispose();
        }
      }
      scope.remove(item);
    }
  }

  public edge(): Line {
    const color = new Color(this.edgeColor);
    const lineGeometry = new LineGeometry();
    lineGeometry.setPositions([0, 0, 0, 0, 0, 0]);
    lineGeometry.setColors([color.r, color.g, color.b, color.r, color.g, color.b]);

    const lineMaterial = new LineMaterial();
    lineMaterial.color = new Color(this.edgeColor);
    lineMaterial.linewidthUniform = this.lineWidth;
    lineMaterial.vertexColors = false;

    const edge = new Line(lineGeometry, lineMaterial);
    edge.computeLineDistances();
    edge.scale.set(1, 1, 1);
    edge.visible = true;
    return edge;
  }

  public label(text: string = "", color: number = this.labelBackground, textColor: number = this.textColor): TextSprite {
    const label = new TextSprite(text, textColor);
    label.material.depthTest = false;
    label.material.opacity = 1;
    const c = new Color(color);
    label.backgroundColor = {
      r: Math.floor(c.r * 255),
      g: Math.floor(c.g * 255),
      b: Math.floor(c.b * 255),
      a: 1,
    };
    return label;
  }

  public edgelabel(text: string = ""): TextSprite {
    return this.label(text, this.edgeLabelBackground, this.edgeLabelTextColor);
  }

  public marker(color?: number): Mesh {
    const marker = new Mesh(this.markerGeometry, this.markerMaterial(color));
    marker.userData = {};
    return marker;
  }

  protected markerMaterial(color?: number): Material {
    const material = new MeshBasicMaterial({
      color: color || this.markerColor,
      depthTest: false,
      depthWrite: false,
    });
    return material;
  }

  public get highlighted(): boolean {
    return this._highlighted;
  }

  public set highlighted(value: boolean) {
    this._highlighted = value;

    if (value === true) {
      for (const marker of this.markers.children) {
        ((marker as Mesh).material as MeshBasicMaterial).color.setHex(this.highlightColor);
      }
      for (const e of this.edges.children) {
        ((e as Line).material as LineMaterial).color = new Color(this.highlightColor);
      }
    } else {
      for (const marker of this.markers.children) {
        ((marker as Mesh).material as MeshBasicMaterial).color.setHex(this.markerColor);
      }
      for (const e of this.edges.children) {
        ((e as Line).material as LineMaterial).color = new Color(this.edgeColor);
      }
    }
  }

  public get markerColor(): number {
    return this._markerColor;
  }

  public set markerColor(value: number) {
    this._markerColor = value;
  }

  public get edgeColor(): number {
    return this._edgeColor;
  }

  public set edgeColor(value: number) {
    this._edgeColor = value;
  }

  public get textColor(): number {
    return this._textColor;
  }

  public set textColor(value: number) {
    this._textColor = value;
  }

  public get ambientColor(): number {
    return this._ambientColor;
  }

  public set ambientColor(value: number) {
    this._ambientColor = value;
  }

  public get emissiveColor(): number {
    return this._emissiveColor;
  }

  public set emissiveColor(value: number) {
    this._emissiveColor = value;
  }

  public get lineWidth(): number {
    return this._lineWidth;
  }

  public set lineWidth(value: number) {
    this._lineWidth = value;
  }

  public get markerGeometry(): BufferGeometry {
    return this._markerGeometry;
  }

  public set markerGeometry(value: BufferGeometry) {
    this._markerGeometry = value;
  }

  public get edges(): Group {
    return this._edges;
  }

  public get labels(): Group {
    return this._labels;
  }

  public get markers(): Group {
    return this._markers;
  }

  public get edgeLabels(): Group {
    return this._edgeLabels;
  }

  public get measureId(): string {
    return this._measureId;
  }

  public get measureType(): MeasureType {
    return this._measureType;
  }

  public get highlightColor(): number {
    return this._highlightColor;
  }

  public set highlightColor(value: number) {
    this._highlightColor = value;
  }

  public get labelBackground(): number {
    return this._labelBackground;
  }

  public set labelBackground(value: number) {
    this._labelBackground = value;
  }

  public get edgeLabelBackground(): number {
    return this._edgeLabelBackground;
  }

  public set edgeLabelBackground(value: number) {
    this._edgeLabelBackground = value;
  }

  public get edgeLabelTextColor(): number {
    return this._edgeLabelTextColor;
  }

  public set edgeLabelTextColor(value: number) {
    this._edgeLabelTextColor = value;
  }
}
