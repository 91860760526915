import { Color, MaterialParameters, ShaderMaterial, ShaderMaterialParameters, UniformsLib, UniformsUtils, Vector2 } from "three";
import vertexShader from "./LineMaterial.vs";
import fragmentShader from "./LineMaterial.fs";

export interface LineMaterialParameters extends MaterialParameters {
  color?: number;
  dashed?: boolean;
  dashScale?: number;
  dashSize?: number;
  gapSize?: number;
  linewidth?: number;
  resolution?: Vector2;
}

export class LineMaterial extends ShaderMaterial {
  public readonly isLineMaterial: boolean = true;
  public dashed: boolean = false;

  public constructor(parameters?: LineMaterialParameters) {
    super();

    this.type = "LineMaterial";
    this.uniforms = UniformsUtils.clone(
      UniformsUtils.merge([
        UniformsLib.common,
        UniformsLib.fog,
        {
          linewidth: { value: 1 },
          resolution: { value: new Vector2(1, 1) },
          dashScale: { value: 1 },
          dashSize: { value: 1 },
          gapSize: { value: 1 },
          opacity: { value: 1 },
        },
      ]),
    );
    this.vertexShader = vertexShader;
    this.fragmentShader = fragmentShader;
    this.clipping = true;

    if (parameters) {
      this.setValues(parameters);
    }
    console.log(this.uniforms);
  }

  public get color(): Color {
    return this.uniforms.diffuse.value;
  }

  public set color(v: Color) {
    this.uniforms.diffuse.value = v;
  }

  public get linewidthUniform(): number {
    return this.uniforms.linewidth.value;
  }

  public set linewidthUniform(v: number) {
    this.uniforms.linewidth.value = v;
  }

  public get dashScale(): number {
    return this.uniforms.dashScale.value;
  }

  public set dashScale(v: number) {
    this.uniforms.dashScale.value = v;
  }

  public get dashSize(): number {
    return this.uniforms.dashSize.value;
  }

  public set dashSize(v: number) {
    this.uniforms.dashSize.value = v;
  }

  public get gapSize(): number {
    return this.uniforms.gapSize.value;
  }

  public set gapSize(v: number) {
    this.uniforms.gapSize.value = v;
  }

  public get opacityUniform(): number {
    return this.uniforms.opacity.value;
  }

  public set opacityUniform(v: number) {
    this.uniforms.opacity.value = v;
  }

  public get resolution(): Vector2 {
    return this.uniforms.resolution.value;
  }

  public set resolution(v: Vector2) {
    this.uniforms.resolution.value = v;
  }
}
