import dom from "gis3d/wf/util/DomUtils";
import ui from "gis3d/wf/ui/style/UiStyle";
import i18n from "gis3d/wf/i18n/I18N";

import { Filler } from "gis3d/wf/ui/layout/Filler";
import { Pane } from "gis3d/wf/ui/layout/Pane";
import { Region } from "gis3d/wf/ui/layout/Region";

import { CityvuNavbar } from "gis3d/cityvu/gui/CityvuNavbar";
import { Form } from "gis3d/wf/ui/widget/form/Form";
import { ButtonGroup, ButtonGroupMode } from "gis3d/wf/ui/widget/ButtonGroup";
import { Button } from "gis3d/wf/ui/widget/Button";
import { Select } from "gis3d/wf/ui/widget/form/Select";

import { CityvuGuiCallbacks } from "gis3d/cityvu/gui/CityvuGuiCallbacks";
import { GuiOptions } from "gis3d/cityvu/gui/GuiOptions";

import { Compass } from "gis3d/cityvu/gui/Compass";
import { Navigator } from "gis3d/cityvu/gui/Navigator";
import { OverlayArea } from "gis3d/cityvu/gui/OverlayArea";
import { LayersWidget } from "./LayersWidget";
import { ControlsType } from "gis3d/cityvu/core/three/controls/ControlsType";
import { MessageBar } from "gis3d/wf/ui/widget/MessageBar";
import { MeasureList } from "./MeasuresList";
import { LayerTreeNode } from "../core/three/scene/LayerTreeStore";
import { UserSettingsDialog } from "./UserSettingsDialog";
import { MeasureType } from "../core/measure/MeasureType";
import { ConnectionStatus } from "gis3d/wf/ui/widget/ConnectionStatus";
import { Toaster } from "gis3d/wf/ui/toast/Toaster";
import { InfoDialog } from "./InfoDialog";

export class CityvuGui extends Filler {
  protected _messageBar!: MessageBar;
  protected _topPane!: Pane;
  protected _mainPane!: Pane;
  protected _navbar!: CityvuNavbar;
  protected _compass?: Compass;
  protected _navigator?: Navigator;
  protected _callbacks: CityvuGuiCallbacks;
  protected _show: boolean = true;

  protected _controlsButtonGroup?: ButtonGroup;
  protected _measuresButtonGroup?: ButtonGroup;
  protected _layersButton?: Button;
  protected _channelSelect?: Select;
  protected _overlayArea?: OverlayArea;

  protected _layersWidget?: LayersWidget;
  protected _measuresList?: MeasureList;
  protected _connectionStatus!: ConnectionStatus;

  protected _userSettingsDialog?: UserSettingsDialog;
  protected _userSettingsButton?: Button;

  protected _infoDialog?: InfoDialog;
  protected _infoButton?: Button;

  protected _toaster!: Toaster;

  constructor() {
    super(true);
    this._callbacks = new CityvuGuiCallbacks();
  }

  public build() {
    super.build();
    this.addClasses(["cityvu"]);

    this._mainPane = new Pane();
    this.addChild(this.mainPane);

    this._topPane = new Pane(Region.Top);
    this.topPane.init();
    this.topPane.addClasses([ui.p("CityvuTopPane")]);
    this.addChild(this._topPane);

    this._messageBar = new MessageBar();
    this.messageBar.init();
    this.addChild(this.messageBar);

    this._navbar = new CityvuNavbar();
    this.navbar.init();
    this.buildNavbar();
    this.topPane.addChild(this._navbar);

    this.buildNavigator();
    this.buildCompass();

    this.buildInfo();
    this.buildOverview();
    this.buildOverlayArea();
    this.buildLayerWidget();
    this.buildMeasuresList();
    this.buildUserSettings();
    this.buildConnectionStatus();

    this._toaster = new Toaster();
  }

  protected buildConnectionStatus(): void {
    this._connectionStatus = new ConnectionStatus();
    this.connectionStatus.init();
    this.connectionStatus.displayed = false;
    this.mainPane.addChild(this.connectionStatus);
  }

  protected buildUserSettings(): void {
    this._userSettingsDialog = new UserSettingsDialog();
    this.userSettingsDialog.onHide = () => {
      this.callbacks.onUserSettingDialogHide();
    };
    this.userSettingsDialog.onShow = () => {
      this.callbacks.onUserSettingDialogShow();
    };
    this.userSettingsDialog.init();
  }

  protected buildLayerWidget(): void {
    this._layersWidget = new LayersWidget();
    this.layersWidget.init();
    this.layersWidget.onLayerCheck = (node: LayerTreeNode) => {
      this.callbacks.onLayerCheck(node);
    };
    this.mainPane.addChild(this.layersWidget);
  }

  protected buildMeasuresList(): void {
    this._measuresList = new MeasureList();
    this.measuresList.init();
    this.measuresList.displayed = false;
    this.mainPane.addChild(this.measuresList);
  }

  protected buildOverlayArea(): void {
    this._overlayArea = new OverlayArea();
    this.mainPane.addChild(this.overlayArea);
  }

  protected buildNavigator(): void {
    this._navigator = new Navigator();
    this.navigator.arrows[0].enabled = true;
    this.navigator.arrows[0].onClick = () => console.log("click");
    this.mainPane.addChild(this.navigator);
  }

  // TODO buildOverview
  protected buildOverview(): void {}

  protected buildCompass(): void {
    this._compass = new Compass();
    this.mainPane.addChild(this.compass);
  }

  protected buildInfo(): void {
    this._infoDialog = new InfoDialog();
    this.infoDialog.onHide = () => {
      this.callbacks.onInfoDialogHide();
    };
    this.infoDialog.onShow = () => {
      this.callbacks.onInfoDialogShow();
      this.infoDialog.setPosition();
    };
    this.infoDialog.init();
  }

  protected buildNavbar(): void {
    let form = new Form();
    form.init();
    form.addClasses([ui.Form.inline, ui.Spacing.w100]);
    this.navbar.addChild(form);

    // controls buttons
    this._controlsButtonGroup = new ButtonGroup(ButtonGroupMode.RADIO);
    this.controlsButtonGroup.onToggle = (button: Button, idx: number, oldIdx: number) => this._callbacks.onControlsToggle(button, idx, oldIdx);
    this.controlsButtonGroup.init();
    form.addChild(this.controlsButtonGroup);

    let b = new Button(ui.Button.buttonLight);
    b.init();
    b.label = i18n.i("cityvu.gui.controls.earth");
    b.identifier = ControlsType.EARTH;
    b.icon = ui.fa("arrows-alt");
    this.controlsButtonGroup.addChild(b);

    b = new Button(ui.Button.buttonLight);
    b.init();
    b.identifier = ControlsType.ORBIT;
    b.label = i18n.i("cityvu.gui.controls.orbit");
    b.icon = ui.fa("sync");
    this.controlsButtonGroup.addChild(b);

    b = new Button(ui.Button.buttonLight);
    b.init();
    b.identifier = ControlsType.FPS;
    b.label = i18n.i("cityvu.gui.controls.look");
    b.icon = ui.fa("eye");
    this.controlsButtonGroup.addChild(b);

    b = new Button(ui.Button.buttonLight);
    b.init();
    b.label = i18n.i("cityvu.gui.controls.drive");
    b.identifier = ControlsType.CONSTRAINED;
    b.icon = ui.fa("car");
    this.controlsButtonGroup.addChild(b);

    // measurements buttons
    this._measuresButtonGroup = new ButtonGroup(ButtonGroupMode.RADIO_UNSETTABLE);
    this.measuresButtonGroup.onToggle = (button: Button, idx: number, oldIdx: number) => this._callbacks.onMeasurementsToggle(button, idx, oldIdx);
    this.measuresButtonGroup.init();
    this.measuresButtonGroup.addClasses([ui.Spacing.ml2]);
    form.addChild(this.measuresButtonGroup);

    b = new Button(ui.Button.buttonLight);
    b.init();
    b.identifier = MeasureType.INFO;
    b.label = i18n.i("cityvu.gui.measurement.query");
    b.icon = ui.fa("dot-circle");
    this.measuresButtonGroup.addChild(b);

    b = new Button(ui.Button.buttonLight);
    b.init();
    b.identifier = MeasureType.LENGTH;
    b.label = i18n.i("cityvu.gui.measurement.line");
    b.icon = ui.fa("pencil-alt");
    this.measuresButtonGroup.addChild(b);

    b = new Button(ui.Button.buttonLight);
    b.init();
    b.identifier = MeasureType.AREA;
    b.label = i18n.i("cityvu.gui.measurement.area");
    b.icon = ui.fa("draw-polygon");
    this.measuresButtonGroup.addChild(b);

    b = new Button(ui.Button.buttonLight);
    b.init();
    b.identifier = MeasureType.ANGLE;
    b.label = i18n.i("cityvu.gui.measurement.angle");
    b.icon = ui.fa("check");
    this.measuresButtonGroup.addChild(b);

    this._layersButton = new Button(ui.Button.buttonLight);
    this.layersButton.init();
    this.layersButton.onClick = () => this.callbacks.onLayersClick();
    this.layersButton.icon = ui.fa("layer-group");
    this.layersButton.label = i18n.i("cityvu.gui.controls.layers");
    this.layersButton.addClasses([ui.Spacing.ml2]);
    form.addChild(this.layersButton);

    this._userSettingsButton = new Button(ui.Button.buttonLight);
    this.userSettingsButton.init();
    this.userSettingsButton.onClick = () => this.onUserSettingsButtonClick();
    this.userSettingsButton.icon = ui.fa("cogs");
    // this.userSettingsButton.label = i18n.i("cityvu.gui.controls.userSettings");
    this.userSettingsButton.addClasses([ui.Spacing.ml2]);
    form.addChild(this.userSettingsButton);

    this._infoButton = new Button(ui.Button.buttonLink);
    this.infoButton.init();
    this.infoButton.onClick = () => this.onInfoButtonClick();
    this.infoButton.icon = ui.fa("question-circle");
    this.infoButton.addClasses([ui.Spacing.mlAuto, ui.Color.textLight, ui.Spacing.p0]);
    form.addChild(this.infoButton);
  }

  protected onInfoButtonClick(): void {
    this.infoDialog.show();
  }

  protected onUserSettingsButtonClick(): void {
    this.userSettingsDialog.show();
  }

  public startup() {
    this.attach(dom.body());
    super.startup();
  }

  public get infoButton(): Button {
    return this._infoButton!;
  }

  public get topPane(): Pane {
    return this._topPane;
  }

  public get mainPane(): Pane {
    return this._mainPane;
  }

  public get messageBar(): MessageBar {
    return this._messageBar;
  }

  public get navbar(): CityvuNavbar {
    return this._navbar;
  }

  public get callbacks(): CityvuGuiCallbacks {
    return this._callbacks;
  }

  public get controlsButtonGroup(): ButtonGroup {
    return this._controlsButtonGroup!;
  }

  public get measuresButtonGroup(): ButtonGroup {
    return this._measuresButtonGroup!;
  }

  public get channelSelect(): Select {
    return this._channelSelect!;
  }

  public get layersButton(): Button {
    return this._layersButton!;
  }

  public get compass(): Compass {
    return this._compass!;
  }

  public get navigator(): Navigator {
    return this._navigator!;
  }

  public get layersWidget(): LayersWidget {
    return this._layersWidget!;
  }

  public get measuresList(): MeasureList {
    return this._measuresList!;
  }

  public get overlayArea(): OverlayArea {
    return this._overlayArea!;
  }

  public get infoDialog(): InfoDialog {
    return this._infoDialog!;
  }

  public get userSettingsDialog(): UserSettingsDialog {
    return this._userSettingsDialog!;
  }

  public get userSettingsButton(): Button {
    return this._userSettingsButton!;
  }

  public get connectionStatus(): ConnectionStatus {
    return this._connectionStatus;
  }

  public get show(): boolean {
    return this._show;
  }

  public get toaster(): Toaster {
    return this._toaster;
  }

  public set show(s: boolean) {
    this._show = s;
    this.controlsButtonGroup.visible = s;
    this.measuresButtonGroup.visible = s;
    this.userSettingsButton.visible = s;
    this.layersButton.visible = s;
    this.compass.visible = s;
    this.navigator.visible = s;
  }

  public set options(opts: GuiOptions) {
    this.configure(opts);
  }

  public configure(options: GuiOptions): void {
    this.layersWidget.displayed = false;
    this.layersButton.displayed = options.tools != null && options.tools.layerSelector;
    this.userSettingsButton.displayed = options.userSettings;
    this.navigator.displayed = options.tools != null && options.tools.navigator;
    this.compass.displayed = options.tools != null && options.tools.compass;

    const cbg = this.controlsButtonGroup;
    cbg.displayed = options.controls;
    cbg.displayButton(cbg.findIdentifier(ControlsType.EARTH)!, options.enabledControls!.earth);
    cbg.displayButton(cbg.findIdentifier(ControlsType.FPS)!, options.enabledControls!.fps);
    cbg.displayButton(cbg.findIdentifier(ControlsType.ORBIT)!, options.enabledControls!.orbit);
    cbg.displayButton(cbg.findIdentifier(ControlsType.CONSTRAINED)!, options.enabledControls!.constrained);

    let controlsToBeSelected: any = options.selectedControls ? cbg.findIdentifier(options.selectedControls) : null;
    if (controlsToBeSelected == null) {
      for (const b of cbg.children) {
        if ((b as Button).displayed) {
          controlsToBeSelected = b;
          break;
        }
      }
    }
    if (controlsToBeSelected != null) {
      cbg.toggle(controlsToBeSelected);
    }

    const mbg = this.measuresButtonGroup;
    mbg.displayed = options.measurements;
    mbg.displayButton(mbg.findIdentifier(MeasureType.ANGLE)!, options.enabledMeasurements!.angle);
    mbg.displayButton(mbg.findIdentifier(MeasureType.AREA)!, options.enabledMeasurements!.area);
    mbg.displayButton(mbg.findIdentifier(MeasureType.INFO)!, options.enabledMeasurements!.info);
    mbg.displayButton(mbg.findIdentifier(MeasureType.LENGTH)!, options.enabledMeasurements!.line);
  }
}
