import {
  Box3,
  BufferAttribute,
  EdgesGeometry,
  Float32BufferAttribute,
  InstancedBufferGeometry,
  InstancedInterleavedBuffer,
  InterleavedBufferAttribute,
  LineSegments,
  Matrix4,
  Mesh,
  Sphere,
  Vector3,
  WireframeGeometry,
} from "three";

export class LineSegmentsGeometry extends InstancedBufferGeometry {
  public readonly isLineSegmentsGeometry: boolean = true;

  public constructor() {
    super();

    this.type = "LineSegmentsGeometry";

    const positions = [-1, 2, 0, 1, 2, 0, -1, 1, 0, 1, 1, 0, -1, 0, 0, 1, 0, 0, -1, -1, 0, 1, -1, 0];
    const uvs = [-1, 2, 1, 2, -1, 1, 1, 1, -1, -1, 1, -1, -1, -2, 1, -2];
    const index = [0, 2, 1, 2, 3, 1, 2, 4, 3, 4, 5, 3, 4, 6, 5, 6, 7, 5];

    this.setIndex(index);
    this.setAttribute("position", new Float32BufferAttribute(positions, 3));
    this.setAttribute("uv", new Float32BufferAttribute(uvs, 2));
  }

  public applyMatrix4(matrix: Matrix4): LineSegmentsGeometry {
    const start = this.attributes.instanceStart;
    const end = this.attributes.instanceEnd;

    if (start !== undefined) {
      start.applyMatrix4(matrix);
      end.applyMatrix4(matrix);
      start.needsUpdate = true;
    }

    if (this.boundingBox !== null) {
      this.computeBoundingBox();
    }

    if (this.boundingSphere !== null) {
      this.computeBoundingSphere();
    }

    return this;
  }

  public setPositions(arr: number[] | Float32Array | ArrayLike<number>): LineSegmentsGeometry {
    let lineSegments: ArrayLike<number> | undefined = undefined;

    if (arr instanceof Float32Array) {
      lineSegments = arr;
    } else if (Array.isArray(arr)) {
      lineSegments = new Float32Array(arr);
    }

    if (lineSegments) {
      const instanceBuffer = new InstancedInterleavedBuffer(lineSegments, 6, 1); // xyz, xyz
      this.setAttribute("instanceStart", new InterleavedBufferAttribute(instanceBuffer, 3, 0)); // xyz
      this.setAttribute("instanceEnd", new InterleavedBufferAttribute(instanceBuffer, 3, 3)); // xyz

      this.computeBoundingBox();
      this.computeBoundingSphere();
    }

    return this;
  }

  public setColors(array: number[] | Float32Array | ArrayLike<number>): LineSegmentsGeometry {
    let colors: ArrayLike<number> | undefined = undefined;

    if (array instanceof Float32Array) {
      colors = array;
    } else if (Array.isArray(array)) {
      colors = new Float32Array(array);
    }

    if (colors) {
      const instanceColorBuffer = new InstancedInterleavedBuffer(colors, 6, 1); // rgb, rgb
      this.setAttribute("instanceColorStart", new InterleavedBufferAttribute(instanceColorBuffer, 3, 0)); // rgb
      this.setAttribute("instanceColorEnd", new InterleavedBufferAttribute(instanceColorBuffer, 3, 3)); // rgb
    }

    return this;
  }

  public fromWireframeGeometry(geometry: WireframeGeometry): LineSegmentsGeometry {
    this.setPositions(geometry.attributes.position.array);
    return this;
  }

  public fromEdgesGeometry(geometry: EdgesGeometry): LineSegmentsGeometry {
    this.setPositions(geometry.attributes.position.array);
    return this;
  }

  public fromMesh(mesh: Mesh): LineSegmentsGeometry {
    this.fromWireframeGeometry(new WireframeGeometry(mesh.geometry));
    return this;
  }

  public fromLineSegments(lineSegments: LineSegments): LineSegmentsGeometry {
    const geometry = lineSegments.geometry as any;
    if (geometry.isGeometry) {
      this.setPositions(geometry.vertices);
    } else if (geometry.isBufferGeometry) {
      this.setPositions(geometry.attributes.position.array); // assumes non-indexed
    }
    return this;
  }

  public computeBoundingBox(): void {
    const box = new Box3();

    return (() => {
      if (this.boundingBox === null) {
        this.boundingBox = new Box3();
      }

      const start = this.attributes.instanceStart;
      var end = this.attributes.instanceEnd;

      if (start !== undefined && end !== undefined) {
        this.boundingBox.setFromBufferAttribute(start as BufferAttribute);
        box.setFromBufferAttribute(end as BufferAttribute);
        this.boundingBox.union(box);
      }
    })();
  }

  public computeBoundingSphere(): void {
    const vector = new Vector3();

    return (() => {
      if (this.boundingSphere === null) {
        this.boundingSphere = new Sphere();
      }

      if (this.boundingBox === null) {
        this.computeBoundingBox();
      }

      var start = this.attributes.instanceStart;
      var end = this.attributes.instanceEnd;

      if (start !== undefined && end !== undefined) {
        var center = this.boundingSphere.center;

        this.boundingBox!.getCenter(center);

        var maxRadiusSq = 0;

        for (var i = 0, il = start.count; i < il; i++) {
          vector.fromBufferAttribute(start, i);
          maxRadiusSq = Math.max(maxRadiusSq, center.distanceToSquared(vector));

          vector.fromBufferAttribute(end, i);
          maxRadiusSq = Math.max(maxRadiusSq, center.distanceToSquared(vector));
        }

        this.boundingSphere.radius = Math.sqrt(maxRadiusSq);

        if (isNaN(this.boundingSphere.radius)) {
          console.error("THREE.LineSegmentsGeometry.computeBoundingSphere(): Computed radius is NaN. The instanced position data is likely to have NaN values.", this);
        }
      }
    })();
  }
}
