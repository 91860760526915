import { Dialog } from "gis3d/wf/ui/widget/Dialog";
import I18N from "gis3d/wf/i18n/I18N";
import { Form } from "gis3d/wf/ui/widget/form/Form";
import { Button } from "gis3d/wf/ui/widget/Button";
import UiStyle from "gis3d/wf/ui/style/UiStyle";
import DomUtils from "gis3d/wf/util/DomUtils";
import { LabeledInput } from "gis3d/wf/ui/widget/form/LabeledInput";
import { Input } from "gis3d/wf/ui/widget/form/Input";

export class UserSettingsDialog extends Dialog {
    protected _form!: Form;
    protected _saveButton!: Button;
    protected _cancelButton!: Button;

    public constructor() {
        super();
        this.hasFooter = true;

        this.draggable = false;
        this.centered = false;
        this.hasCloseButton = false;
        this.title = I18N.i("cityvu.gui.userSettings");
        this.onHide = () => {
            console.log("hidden");
        };
    }

    public startup(): void {
        super.startup();
        this.populateFooter();
        this._form = new Form();
        this.form.init();
        // test
        const labeledInput = new LabeledInput();
        labeledInput.label = "Ciao";
        labeledInput.name = "ciao";
        labeledInput.help = "Qui scrivi ciao";
        labeledInput.inputComponent = new Input();
        labeledInput.inputComponent.init();
        labeledInput.init();
        this.form.addEntry(labeledInput);
        //
        this.addChild(this.form);
    }

    protected populateFooter() : void {
        this._cancelButton = new Button(UiStyle.Button.buttonSecondary);
        this.cancelButton.init();
        this.cancelButton.icon = UiStyle.fa("times");
        this.cancelButton.label = I18N.i("cancel");
        this.cancelButton.onClick = () => { this.hide(); };
        DomUtils.append(this.modalFooter!, this.cancelButton.domNode!);

        this._saveButton = new Button();
        this.saveButton.init();
        this.saveButton.icon = UiStyle.fa("save");
        this.saveButton.label = I18N.i("save");
        this.saveButton.onClick = () => { this.saveAndClose(); };
        DomUtils.append(this.modalFooter!, this.saveButton.domNode!);
    }

    protected saveAndClose() : void {
        const data : Map<string, any> = this.form.data;
        console.log(data);
        this.hide();
    }

    public get form(): Form {
        return this._form;
    }

    public get saveButton(): Button {
        return this._saveButton;
    }

    public get cancelButton(): Button {
        return this._cancelButton;
    }

}
