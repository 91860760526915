import { Observable, Observer } from "rxjs";
import { Font, FontLoader } from "three";

export class FontRegistry {
  public static readonly fontRegistry: Map<string, Font> = new Map();
  public static readonly urlRegistry: Map<string, string> = new Map();

  public static readonly ROBOTO_MEDIUM: string = "roboto-medium";

  public static register(fontName: string, fontUrl: string): void {
    FontRegistry.urlRegistry.set(fontName, fontUrl);
  }

  public static load(fontName: string): Observable<Font> {
    return new Observable<Font>((obs: Observer<Font>) => {
      if (FontRegistry.fontRegistry.has(fontName)) {
        obs.next(FontRegistry.fontRegistry.get(fontName)!);
        obs.complete();
      } else {
        const url = FontRegistry.urlRegistry.get(fontName);
        if (url) {
          new FontLoader().loadAsync(url).then(
            (font) => {
              obs.next(font);
              obs.complete();
            },
            (err) => {
              obs.error(err);
            },
          );
        } else {
          obs.error(new Error("no url for font"));
        }
      }
    });
  }
}
