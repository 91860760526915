import vertexShader from "./Clouds.vs";
import fragmentShader from "./PerlinClouds.fs";

import {
    Vector3,
    DataTexture,
    ShaderMaterial,
    Mesh,
    ParametricGeometry,
    RGBAFormat,
    RepeatWrapping,
    LinearFilter,
    DoubleSide,
    BoxBufferGeometry,
    SphereBufferGeometry,
    PlaneBufferGeometry,
    MeshBasicMaterial,
} from "three";

export class Clouds extends Mesh {
    constructor(readonly noiseSize: number = 256) {
        super();
        // random noise
        const size = noiseSize * noiseSize;
        const data = new Uint8Array(4 * size);

        for (let i = 0; i < size * 4; i++) {
            data[i] = Math.random() * 255;
        }

        let dt = new DataTexture(data, noiseSize, noiseSize, RGBAFormat);
        dt.wrapS = RepeatWrapping;
        dt.wrapT = RepeatWrapping;
        dt.magFilter = LinearFilter;
        dt.minFilter = LinearFilter;
        dt.needsUpdate = true;

        //this.material = new MeshBasicMaterial( {color: 0xffff00, side: DoubleSide} );

        this.material = new ShaderMaterial({
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            uniforms: {
                texture: { value: dt },
                time: { value: 0 },
                sharp: { value: 0.4 },
                cover: { value: 0.3 },
                clouds: { value: 0.3 },
                /*
                texture: { type: "t", value: dt },
                time: { type: "f", value: 0 },
                sharp: { type: "f", value: 0.4 },
                cover: { type: "f", value: 0.3 },
                clouds: { type: "f", value: 0.3 },
                */
            },
            side: DoubleSide,
            transparent: true,
            depthTest: true,
            depthWrite: true,
        });

        this.geometry = new PlaneBufferGeometry(16, 16, 5, 5); // SphereBufferGeometry(1, 10, 8, 0, 2 * Math.PI, 0, Math.PI / 2);
        this.position.z = 1000;
    }

    public update(delta: number) {
        (<ShaderMaterial>this.material).uniforms.time.value += delta;
    }

    public uniforms(): any {
        return (<ShaderMaterial>this.material).uniforms;
    }
}
