import NumberUtils from "gis3d/wf/util/NumberUtils";
import { Group, Object3D } from "three";
import { Engine } from "../Engine";
import { Layer3dState } from "./Layer3dState";
import { Layer3dType } from "./Layer3dType";
import { ExtendedIntersection } from "./picker/Picker";
import { PickRequest } from "./picker/PickRequest";
import { PickResults } from "./picker/PickResults";

export class Layer3d {
    private _id: string | null = null;
    private _object: Group;
    private _canReceiveCast: boolean = true;
    private _pickingBlacklist: Array<Object3D> | null = null;
    private _name: string | null = null;
    private _label: string | null = null;
    private _state: Layer3dState = Layer3dState.CREATED;

    protected _engine!: Engine;

    constructor() {
        this._object = new Group();
    }

    public update(delta: number): void {}

    public add(item: Object3D): void {
        this.object.add(item);
    }

    public remove(item: Object3D): void {
        this.object.remove(item);
    }

    public get visible(): boolean {
        return this.object.visible;
    }

    public set visible(value: boolean) {
        this.object.visible = value;
    }

    public getPickableObjects(request: PickRequest): Array<Object3D> {
        let list = this.listPickableObjects(request);
        return this.filterPickableObjects(list);
    }

    protected listPickableObjects(request: PickRequest): Object3D[] {
        return this.object.children;
    }

    protected filterPickableObjects(list: Object3D[]): Object3D[] {
        if (this.pickingBlacklist != null) {
            return list.filter(i => this.pickingBlacklist!.indexOf(i) < 0);
        }
        return list;
    }

    public resultsFromIntersections(request: PickRequest, intersections: ExtendedIntersection[], results: PickResults): void {
        const picks: Array<ExtendedIntersection> = [];
        const globalFilter = request.filter;
        const layerFilter = request.layerFilter != null ? request.layerFilter.get(this.id) : undefined;
        const ll = intersections.length;
        for (let j = 0; j < ll; j++) {
            const i = intersections[j];
            if ((globalFilter == null || globalFilter(i)) && (layerFilter == null || layerFilter(i) === true)) {
                picks.push(i);
            }
        }
        results.objects.set(this.id, picks);
    }

    protected onEngineAssigment(): void {
        this.state = Layer3dState.READY;
    }

    public get canReceiveCast(): boolean {
        return this._canReceiveCast;
    }

    public set canReceiveCast(value: boolean) {
        this._canReceiveCast = value;
    }

    public get type(): Layer3dType {
        return Layer3dType.CUSTOM;
    }

    public get object(): Group {
        return this._object;
    }

    public set object(value: Group) {}

    public get pickingBlacklist(): Array<Object3D> | null {
        return this._pickingBlacklist;
    }

    public set pickingBlacklist(value: Array<Object3D> | null) {
        this._pickingBlacklist = value;
    }

    public get id(): string {
        if (this._id == null) {
            this.id = NumberUtils.createUuid();
        }
        return this._id!;
    }

    public set id(value: string) {
        this._id = value;
    }

    public get name(): string {
        if (this._name == null) {
            this.name = this.id;
        }
        return this._name!;
    }

    public set name(value: string) {
        this._name = value;
    }

    public get label(): string {
        if (this._label == null) {
            this.label = this.name;
        }
        return this._label!;
    }

    public set label(value: string) {
        this._label = value;
    }

    public get engine(): Engine {
        return this._engine;
    }

    public set engine(value: Engine) {
        this._engine = value;
        this.onEngineAssigment();
    }

    public get state(): Layer3dState {
        return this._state;
    }

    public set state(value: Layer3dState) {
        this._state = value;
    }
}
