import { Tween, Easing } from "@tweenjs/tween.js";
import { Camera, Ray, Raycaster, Vector2, Vector3 } from "three";

export default class ThreeUtils {
    public static readonly tween = (from: any, to: any, duration: number): Tween<any> => {
        const tween = new Tween(from).easing(Easing.Quartic.Out).to(to, duration);
        return tween;
    };

    public static readonly tweenPosition = (position: Vector3, finalPosition: Vector3, duration: number): Tween<Vector3> => {
        const tween = new Tween(position).easing(Easing.Quartic.Out).to(
            {
                x: finalPosition.x,
                y: finalPosition.y,
                z: finalPosition.z,
            },
            duration,
        );
        return tween;
    };

    public static readonly normalize2dCoordinates = (target: Vector2, width: number, height: number, reuse: boolean = false): Vector2 => {
        const nx = (target.x / width) * 2 - 1;
        const ny = -(target.y / height) * 2 + 1;
        if (reuse) {
            target.x = nx;
            target.y = ny;
            return target;
        }
        return new Vector2(nx, ny);
    };

    public static readonly raycaster = (target: Vector2, camera: Camera, width: number, height: number, raycaster?: Raycaster): Raycaster => {
        const caster = raycaster ? raycaster : new Raycaster();
        caster.setFromCamera(ThreeUtils.normalize2dCoordinates(target, width, height), camera);

        return caster;
    };

    public static readonly mouseRay = (mouse: Vector2, camera: Camera, width: number, height: number): Ray => {
        const normalizedMouse = ThreeUtils.normalize2dCoordinates(mouse, width, height);
        const v = new Vector3(normalizedMouse.x, normalizedMouse.y, 0.5).unproject(camera);
        const o = new Vector3(normalizedMouse.x, normalizedMouse.y, 0).unproject(camera);
        // origin and direction
        return new Ray(o, v.sub(o).normalize());
    };

    public static readonly ray = (target: Vector2, camera: Camera, width: number, height: number, raycaster?: Raycaster): Ray => {
        return ThreeUtils.raycaster(target, camera, width, height, raycaster).ray;
    };

    public static readonly v3str = (v3: Vector3, decimals: number = 3): string => {
        return v3
            .toArray()
            .map(value => value.toFixed(decimals))
            .join(" ");
    };
}
