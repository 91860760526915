import { sRGBEncoding, TextureEncoding } from "three";

export class EngineOptions {
    public alpha: boolean = true;
    public logarithmicDepthBuffer: boolean = true;
    public antialias: boolean = true;
    public pixelRatio: number = window.devicePixelRatio;
    public clearAlpha: number = 1.0;
    public clearColor: number = 0x555555;
    public fog: boolean = true;
    public fogColor: number = 0x222233;
    public gammaOutput: TextureEncoding = sRGBEncoding;
    public shadowMap: boolean = false;
    public cameraHorizontalFov: number = 90;
    public premultipliedAlpha: boolean = false;
    public powerPreference: string = "high-performance";
    public sortObjects: boolean = false;
    // this is the vertical fov
    // public cameraFov:number = 45;
    // in case we want a fixed hFov we could use this to compute the vFov
    // var horizontalFov = 90;
    // vFov = (Math.atan(Math.tan(((horizontalFov / 2) * Math.PI) / 180) / cameraAspect) * 2 * 180) / Math.PI;
    public cameraNear: number = 0.1;
    public cameraFar: number = 10000;
}
