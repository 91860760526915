import { DataSourceType } from "../../data/DataSourceType";
import { DefaultSceneDefinition } from "./default/DefaultSceneDefinition";
import { DefaultSceneDefinitionLayer } from "./default/DefaultSceneDefinitionLayer";
import { SceneDefinition } from "./SceneDefinition";
import { SceneDefinitionDataSource } from "./SceneDefinitionDataSource";
import { SceneDefinitionLayerType } from "./SceneDefinitionLayerType";
import { SceneDefinitionGeoreferenceMode } from "./SceneDefinitionGeoreferenceMode";
import { SceneDefinitionSettingsControl } from "./SceneDefinitionSettingsControl";
import { SceneDefinitionSettingsMode } from "./SceneDefinitionSettingsMode";
import { SceneDefinitionLayerSource } from "./SceneDefinitionLayerSource";
import NumberUtils from "gis3d/wf/util/NumberUtils";

export class SceneDefinitionParser {
    public static parse(stringObject: string): SceneDefinition | null {
        const data = JSON.parse(stringObject);
        if (data != null) {
            try {
                const def: SceneDefinition = new DefaultSceneDefinition();
                def.title = data.title;
                if (data.georeference != null) {
                    const geo = def.georeference;
                    geo.crs = data.georeference.crs;
                    geo.mode = SceneDefinitionGeoreferenceMode[data.georeference.mode as SceneDefinitionGeoreferenceMode];
                    if (geo.mode === undefined) {
                        throw Error("SceneGeoreferenceMode");
                    }
                    if (data.georeference.bounds != null) {
                        const b = data.georeference.bounds;
                        geo.bounds.minX = b.minX;
                        geo.bounds.minY = b.minY;
                        geo.bounds.minZ = b.minZ;
                        geo.bounds.maxX = b.maxX;
                        geo.bounds.maxY = b.maxY;
                        geo.bounds.maxZ = b.maxZ;
                    }
                }
                // SETTINGS
                if (data.settings != null) {
                    const s = def.settings;
                    s.location = data.settings.location;
                    s.lookAt = data.settings.lookAt;
                    s.control = SceneDefinitionSettingsControl[data.settings.control as SceneDefinitionSettingsControl];
                    if (s.control === undefined) {
                        s.control = SceneDefinitionSettingsControl.EARTH;
                    }
                    s.mode = SceneDefinitionSettingsMode[data.settings.mode as SceneDefinitionSettingsMode];
                    if (s.mode === undefined) {
                        throw Error("SceneSettingsMode");
                    }
                }
                // CONTROLS
                if (data.controls != null) {
                    if (data.controls.navigation != null) {
                        def.controls.navigation.earth = data.controls.navigation.earth;
                        def.controls.navigation.orbit = data.controls.navigation.orbit;
                        def.controls.navigation.fps = data.controls.navigation.fps;
                        def.controls.navigation.constrained = data.controls.navigation.constrained;
                    }
                    if (data.controls.measurements != null) {
                        def.controls.measurements.info = data.controls.measurements.info;
                        def.controls.measurements.line = data.controls.measurements.line;
                        def.controls.measurements.area = data.controls.measurements.area;
                        def.controls.measurements.angle = data.controls.measurements.angle;
                    }
                    if (data.controls.tools != null) {
                        def.controls.tools.layers = data.controls.tools.layers;
                        def.controls.tools.compass = data.controls.tools.compass;
                        def.controls.tools.info = data.controls.tools.info;
                        if (data.controls.tools.drivingMode != null) {
                            def.controls.tools.drivingMode.enabled = data.controls.tools.drivingMode.enabled;
                            def.controls.tools.drivingMode.dataSource = data.controls.tools.drivingMode.dataSource;
                        }
                        if (data.controls.tools.userSettings != null) {
                            def.controls.tools.userSettings = data.controls.tools.userSettings;
                        }
                    }
                }
                if (data.layers != null) {
                    for (const l of data.layers) {
                        const layer = new DefaultSceneDefinitionLayer();
                        layer.name = l.name;
                        layer.type = SceneDefinitionLayerType[l.type as SceneDefinitionLayerType];
                        if (layer.type === undefined) {
                            throw Error(JSON.stringify(l));
                        }
                        // prepare sources
                        const sources = l.sources as Array<SceneDefinitionLayerSource>;
                        for (const source of sources) {
                            if (source.id == null) {
                                source.id = NumberUtils.createUuid();
                            }
                        }
                        layer.sources = sources;
                        if (l.id != null) {
                            layer.id = l.id;
                        }
                        layer.label = l.label || l.name || "";
                        layer.enabled = !(l.enabled === false);
                        layer.visible = !(l.visible === false);
                        layer.selectedSource = l.selectedSource;
                        def.layers.push(layer);
                    }
                }
                if (data.dataSources != null) {
                    for (const d of data.dataSources) {
                        const dataSource = {} as SceneDefinitionDataSource;
                        dataSource.code = d.code;
                        dataSource.name = d.name;
                        dataSource.enabled = !(d.enabled === false);
                        dataSource.type = DataSourceType[d.type as DataSourceType];
                        if (dataSource.type === undefined) {
                            throw Error(JSON.stringify(d));
                        }
                        if (d.url != null) {
                            dataSource.url = d.url;
                        }
                        if (d.options != null) {
                            dataSource.options = d.options;
                        }
                        def.dataSources.push(dataSource);
                    }
                }
                // basic layer validation
                const srcCodes = [];
                for (const src of def.dataSources) {
                    srcCodes.push(src.code);
                }
                for (const layer of def.layers) {
                    if (Array.isArray(layer.sources)) {
                        for (const s of layer.sources) {
                            if (srcCodes.indexOf(s.code) == -1) {
                                throw Error("Source not found: " + s);
                            }
                        }
                    } else {
                        throw Error("Sources must be an array");
                    }
                }
                return def;
            } catch (e) {
                console.error(e);
                return null;
            }
        }

        return null;
    }
}
