import { MessageBar } from "gis3d/wf/ui/widget/MessageBar";
import { Measure } from "./Measure";
import { Message, MessageRemover } from "gis3d/wf/ui/widget/Message";
import { MeasureList } from "gis3d/cityvu/gui/MeasuresList";
import { MeasuresCollector } from "./MeasuresCollector";
import { MeasureType } from "./MeasureType";
import { MeasureFactory } from "./MeasureFactory";
import { InputManager } from "../event/InputManager";

export class MeasuresBroker {
  private _messageBar!: MessageBar;
  private _measuresList!: MeasureList;
  private _collector!: MeasuresCollector;
  private _factory!: MeasureFactory;
  private _inputManager!: InputManager;
  // callbacks
  public onAdd = (measure: Measure): void => {};
  public onRemove = (measure: Measure): void => {};
  public onClear = (type?: MeasureType): void => {};
  public onHighlight = (measure: Measure): void => {};
  public onDehighlight = (measure: Measure): void => {};
  public onSend = (measure: Measure): void => {};
  public onCopy = (measure: Measure): void => {};

  constructor() {
    this.collector = new MeasuresCollector();
    this.collector.onCollect = (measure: Measure) => {
      this.onAdd(measure);
    };
    this.collector.onRemove = (measure: Measure) => {
      this.onRemove(measure);
    };
    this.collector.onClear = (type?: MeasureType) => {
      this.onClear(type);
    };
    this.factory = new MeasureFactory();
  }

  public message(message: Message): MessageRemover {
    const id = this.messageBar.add(message);
    return () => {
      this.messageBar.remove(id);
    };
  }

  public get(measureType: MeasureType): Array<Measure> {
    return this.collector.get(measureType)!;
  }

  public add(measure: Measure): void {
    this.collector.collect(measure);
    this.redrawMeasuresList();
  }

  public remove(measure: Measure): void {
    this.collector.remove(measure);
    this.redrawMeasuresList();
  }

  public update(measure: Measure): void {
    // given that measure is not a copy but a reference
    // we should only update the message list
    this.redrawMeasuresList();
  }

  public highlight(measure: Measure): void {
    this.onHighlight(measure);
  }

  public dehighlight(measure: Measure): void {
    this.onDehighlight(measure);
  }

  public clear(type?: MeasureType): void {
    this.collector.clear(type);
    this.redrawMeasuresList();
  }

  private redrawMeasuresList() {
    if (this.measuresList) {
      this.measuresList.displayed = this.collector.count() > 0;
      this.measuresList.render();
    }
  }

  public get collector(): MeasuresCollector {
    return this._collector;
  }

  public set collector(value: MeasuresCollector) {
    this._collector = value;
  }

  public get messageBar(): MessageBar {
    return this._messageBar;
  }

  public set messageBar(value: MessageBar) {
    this._messageBar = value;
  }

  public get measuresList(): MeasureList {
    return this._measuresList;
  }

  public get inputManager(): InputManager {
    return this._inputManager;
  }

  public set inputManager(v: InputManager) {
    this._inputManager = v;
  }

  public set measuresList(value: MeasureList) {
    this._measuresList = value;
    this._measuresList.onClose = () => {
      this.clear();
      this.inputManager.areKeyboardEventsEnabled = true;
    };
    this._measuresList.broker = this;
  }

  public get factory(): MeasureFactory {
    return this._factory;
  }

  public set factory(value: MeasureFactory) {
    this._factory = value;
  }
}
