import { Button } from "gis3d/wf/ui/widget/Button";
import { LayerTreeNode } from "../core/three/scene/LayerTreeStore";

export class CityvuGuiCallbacks {
    public onControlsToggle = (button: Button, idx: number, oldIdx: number): void => { };
    public onMeasurementsToggle = (button: Button, idx: number, oldIdx: number): void => { };
    public onLayersClick = (): void => { };
    public onUserSettingDialogHide = (): void => { };
    public onUserSettingDialogShow = (): void => { };
    public onInfoDialogHide = (): void => { };
    public onInfoDialogShow = (): void => { };
    public onLayerCheck: (node: LayerTreeNode) => void = (node) => { };
}
