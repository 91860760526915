import { Vector3, Vector2, Intersection } from "three";
import { AnimatedControls } from "./AnimatedControls";
import { AppEvent } from "../../event/AppEvent";
import { DragData } from "../../event/DragData";
import { InputManagerEvent } from "../../event/InputManager";

import ThreeUtils from "../ThreeUtils";
import { WheelData } from "../../event/WheelData";
import { Picker } from "../scene/picker/Picker";
import { PickRequest } from "../scene/picker/PickRequest";
import { Scene } from "../scene/Scene";
import { SortedPickResults } from "../scene/picker/SortedPickResults";

export class OrbitControls extends AnimatedControls {
    private _target!: Vector3 | null;

    public movementSpeed: number = 4.0;
    public rotationSpeed: number = 0.4;
    public walkingElevationLock: boolean = false;
    public frictionAmount: number = 12.0;
    public pivotingMaxDistance: number = -1;
    public wheelMultiplier: number = 5.0;
    public minRadius: number = -1;
    public maxRadius: number = -1;

    // TODO make it parametrable
    protected picker: Picker;
    protected pickRequest: PickRequest;
    protected poseWasAnimated: boolean = false;

    protected panDelta: Vector3 = new Vector3(); // XYZ
    protected rotationDelta: Vector3 = new Vector3(); // as yaw, pitch, roll
    protected worldDelta: Vector3 = new Vector3();
    protected userInput: boolean = false;
    protected buffer: Vector3 = new Vector3();
    protected keyState = {
        up: 0,
        down: 0,
        left: 0,
        right: 0,
        forward: 0,
        back: 0,
    };
    protected isShiftDown: boolean = false;
    protected isCtrlDown: boolean = false;

    public constructor(scene?: Scene) {
        super(scene);
        this.picker = new Picker();
        this.pickRequest = new PickRequest();
    }

    public onSceneChange(scene: Scene): void {
        this.pickRequest.scene = scene;
        this.poseWasAnimated = this.scene.pose.animate;
        this.scene.pose.animate = false;
    }

    public enable(): void {
        this.stop();
        this.attachKeyboardEvents();
        this.attachMouseEvents();
        if (this.scene != null) {
            this.onSceneChange(this.scene);
        }
    }

    public disable(): void {
        this.removeEventListeners();
        if (this.scene != null) {
            this.scene.pose.animate = this.poseWasAnimated;
        }
    }

    protected attachMouseEvents() {
        this.addEventListener(this.drag, InputManagerEvent.DRAG);
        this.addEventListener(this.drop, InputManagerEvent.DROP);
        this.addEventListener(this.wheel, InputManagerEvent.MOUSEWHEEL);
    }

    protected attachKeyboardEvents() {
        this.addEventListener(this.keyDown, InputManagerEvent.KEYDOWN);
        this.addEventListener(this.keyUp, InputManagerEvent.KEYUP);
    }

    public stop(): void {
        super.stop();
        this.rotationDelta.set(0, 0, 0);
        this.panDelta.set(0, 0, 0);
        this.worldDelta.set(0, 0, 0);
    }

    public update(delta: number): void {
        if (this.scene == null) {
            return;
        }

        // remove automations if user input has been detected
        if (this.userInput) {
            this.clearTweens();
            this.userInput = false;
        }

        // manage keys
        if (this.walkingElevationLock) {
            const dir = this.scene.pose.direction;
            dir.y = 0;
            dir.normalize();
            // translate along direction but only in XZ (not Y)
            if (this.keyState.forward) {
                this.worldDelta.add(dir.multiplyScalar(this.movementSpeed));
            } else if (this.keyState.back) {
                this.worldDelta.add(dir.multiplyScalar(-this.movementSpeed));
            }
        } else {
            // pan along direction
            if (this.keyState.forward) {
                this.panDelta.y += this.movementSpeed;
            } else if (this.keyState.back) {
                this.panDelta.y += -this.movementSpeed;
            }
        }

        if (this.keyState.right) {
            this.panDelta.x += this.movementSpeed;
        } else if (this.keyState.left) {
            this.panDelta.x += -this.movementSpeed;
        }

        if (this.keyState.up) {
            this.worldDelta.z += this.movementSpeed;
        } else if (this.keyState.down) {
            this.worldDelta.z += -this.movementSpeed;
        }

        const normalizedDelta = Math.min(delta, 1.0);
        const pitchStep = this.rotationDelta.y * normalizedDelta;
        const yawStep = this.rotationDelta.x * normalizedDelta;
        // rotation
        if (this.target != null) {
            // limit to minDistance
            const radius = this.scene.pose.position.distanceTo(this.target);
            if (this.minRadius != -1 && this.panDelta.y > 0 && radius < this.minRadius) {
                this.panDelta.y = 0;
            }
            if (this.maxRadius != -1 && this.panDelta.y < 0 && radius > this.maxRadius) {
                this.panDelta.y = 0;
            }

            // rotate on the sphere
            this.scene.pose.orbitSphere(this.target, pitchStep, yawStep);
        } else {
            // rotate pose
            this.scene.pose.yaw -= yawStep;
            this.scene.pose.pitch -= pitchStep;
        }

        // pan
        this.buffer.copy(this.panDelta).multiplyScalar(normalizedDelta);
        this.scene.pose.pan3(this.buffer);

        // translate
        this.buffer.copy(this.worldDelta).multiplyScalar(normalizedDelta);
        this.scene.pose.translate(this.buffer);

        // apply friction
        const friction = Math.max(1 - this.frictionAmount * normalizedDelta, 0);
        this.panDelta.multiplyScalar(friction);
        this.rotationDelta.multiplyScalar(friction);
        this.worldDelta.multiplyScalar(friction);
    }

    public calculatePivot(): Vector3 | null {
        const w = this.scene.size.w!;
        const h = this.scene.size.h!;
        this.pickRequest.normalizedCoords = ThreeUtils.normalize2dCoordinates(new Vector2(w / 2, h / 2), w, h, true);
        this.pickRequest.filter = (i: Intersection) => this.pivotingMaxDistance === -1 || i.distance < this.pivotingMaxDistance;
        const results = this.picker.pick(this.pickRequest);
        const hit = new SortedPickResults(results).first();
        if (hit != null) {
            return hit.intersection.point.clone();
        }
        return null;
    }

    public wheel(appEvent: AppEvent): void {
        const data = appEvent.value as WheelData;
        // this.target = this.calculatePivot();

        // move forward or backward
        this.panDelta.y += data.delta * this.movementSpeed * this.wheelMultiplier;
    }

    public drag(appEvent: AppEvent): void {
        this.userInput = true;
        const dragData = appEvent.value as DragData;

        if (dragData.isStart) {
            this.target = this.calculatePivot();
        } else {
            if (this.isShiftDown) {
                this.panDelta.x += dragData.delta.x * this.movementSpeed;
                this.panDelta.z -= dragData.delta.y * this.movementSpeed;
            } else {
                if (this.target != null) {
                    // rotate on a sphere of radius R with target as center
                    // x yaw, y pitch, z roll
                    this.rotationDelta.x = dragData.delta.x * this.rotationSpeed;
                    this.rotationDelta.y = dragData.delta.y * this.rotationSpeed;
                    this.rotationDelta.z = 0;
                } else {
                    // yaw
                    this.rotationDelta.x = dragData.delta.x * this.rotationSpeed;
                    // pitch
                    this.rotationDelta.y = dragData.delta.y * this.rotationSpeed;
                    this.rotationDelta.z = 0;
                }
            } 
        }
    }

    public drop(appEvent: AppEvent): void {
        this.userInput = true;
        this.target = null;
    }

    public keyUp(appEvent: AppEvent): void {
        this.userInput = true;
        const event = (appEvent as AppEvent<KeyboardEvent>).value!;
        switch (event.keyCode) {
            //case 16: /* shift */ this.movementSpeedMultiplier = 1; break;

            case 87:
                /*W*/ this.keyState.forward = 0;
                break;
            case 83:
                /*S*/ this.keyState.back = 0;
                break;

            case 65:
                /*A*/ this.keyState.left = 0;
                break;
            case 68:
                /*D*/ this.keyState.right = 0;
                break;

            case 82:
                /*R*/ this.keyState.up = 0;
                break;
            case 70:
                /*F*/ this.keyState.down = 0;
                break;
        }
        this.isShiftDown = event.shiftKey;
        this.isCtrlDown = event.ctrlKey;
    }

    public keyDown(appEvent: AppEvent): void {
        this.userInput = true;
        const event = (appEvent as AppEvent<KeyboardEvent>).value!;
        if (event.altKey) {
            return;
        }

        switch (event.keyCode) {
            //case 16: /* shift */ this.movementSpeedMultiplier = .1; break;

            case 87:
                /*W*/ this.keyState.forward = 1;
                break;
            case 83:
                /*S*/ this.keyState.back = 1;
                break;

            case 65:
                /*A*/ this.keyState.left = 1;
                break;
            case 68:
                /*D*/ this.keyState.right = 1;
                break;

            case 82:
                /*R*/ this.keyState.up = 1;
                break;
            case 70:
                /*F*/ this.keyState.down = 1;
                break;
        }
        this.isShiftDown = event.shiftKey;
        this.isCtrlDown = event.ctrlKey;
    }

    public get target(): Vector3 | null {
        return this._target;
    }

    public set target(value: Vector3 | null) {
        this._target = value;
    }
}
