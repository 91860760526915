import dom from "gis3d/wf/util/DomUtils";
import on from "gis3d/wf/core/On";

import { ConcreteAppEventEmitter } from "./ConcreteAppEventEmitter";
import { InputDelegate } from "./InputDelegate";
import { AppEvent } from "./AppEvent";
import { DragData } from "./DragData";
import { Vector2 } from "three";
import { WheelData } from "./WheelData";
import { ClickData } from "./ClickData";

export class InputManagerEvent {
    public static readonly MOUSEDOWN: string = "MOUSEDOWN";
    public static readonly MOUSEUP: string = "MOUSEUP";
    public static readonly MOUSEMOVE: string = "MOUSEMOVE";
    public static readonly MOUSEWHEEL: string = "MOUSEWHEEL";
    public static readonly MOUSECLICK: string = "MOUSECLICK";
    public static readonly TOUCHSTART: string = "TOUCHSTART";
    public static readonly TOUCHEND: string = "TOUCHEND";
    public static readonly TOUCHMOVE: string = "TOUCHMOVE";
    public static readonly DRAG: string = "DRAG";
    public static readonly DROP: string = "DROP";
    public static readonly KEYDOWN: string = "KEYDOWN";
    public static readonly KEYUP: string = "KEYUP";
    public static readonly CLICK: string = "CLICK";
    public static readonly DOUBLECLICK: string = "DOUBLECLICK";
    public static readonly CONTEXTMENU: string = "CONTEXTMENU";
}

class ConcreteDragData implements DragData {
    public start: Vector2 = new Vector2();
    public delta: Vector2 = new Vector2();
    public end: Vector2 = new Vector2();
    public buttons: number = 0;
    public isStart: boolean = false;
}

export class InputManager extends ConcreteAppEventEmitter {
    public keyboardTarget: any = dom.win();
    public mouseTarget: any = dom.body();
    public mouseUpTarget: any = dom.body();
    public contextMenuTarget: any = dom.body();
    public clickThreshold: number = 2;
    public areKeyboardEventsEnabled: boolean = true;
    public allowedKeys: Array<string> = ["F1", "F2", "F3", "F4", "F5", "F6", "F7", "F8", "F9", "F10", "F11", "F12", "CTRL-r"];
    protected handlers: Array<Function>;
    protected inputDelegates: Array<InputDelegate>;

    protected mouse: Vector2 = new Vector2();
    protected buffer: Vector2 = new Vector2();
    protected dragging: boolean = false;
    protected dragData: DragData = new ConcreteDragData();

    constructor() {
        super();
        this.handlers = [];
        this.inputDelegates = [];
    }

    public addDelegate(delegate: InputDelegate) {
        if (this.inputDelegates.indexOf(delegate) === -1) {
            this.inputDelegates.push(delegate);
            // sort by priority (less is more)
            this.inputDelegates = this.inputDelegates.sort((a, b) => {
                const pa = a.delegationPriority !== undefined ? a.delegationPriority : 0;
                const pb = b.delegationPriority !== undefined ? b.delegationPriority : 0;

                return pa - pb;
            });
        }
    }

    public removeDelegate(delegate: InputDelegate) {
        this.inputDelegates = this.inputDelegates.filter(d => d !== delegate);
    }

    protected delegateEvent(event: AppEvent): void {
        this.inputDelegates.some(delegate => {
            if (delegate.delegationEnabled) {
                return delegate.dispatchEvent(event) === false;
            }
            return false;
        });
    }

    public enable(): void {
        this.handlers.push(on.listen(this.keyboardTarget, "keydown", (e: KeyboardEvent) => this.keyDown(e)));
        this.handlers.push(on.listen(this.keyboardTarget, "keyup", (e: KeyboardEvent) => this.keyUp(e)));

        this.handlers.push(on.listen(this.mouseTarget, "mousedown", (e: MouseEvent) => this.mouseDown(e)));
        this.handlers.push(on.listen(this.mouseUpTarget, "mousemove", (e: MouseEvent) => this.mouseMove(e)));
        this.handlers.push(on.listen(this.mouseUpTarget, "mouseup", (e: MouseEvent) => this.mouseUp(e)));
        this.handlers.push(on.listen(this.mouseTarget, "wheel", (e: WheelEvent) => this.mouseWheel(e)));
        this.handlers.push(on.listen(this.mouseUpTarget, "mouseout", (e: MouseEvent) => this.mouseOut(e)));

        this.handlers.push(on.listen(this.mouseTarget, "touchstart", (e: TouchEvent) => this.touchStart(e)));
        this.handlers.push(on.listen(this.mouseUpTarget, "touchend", (e: TouchEvent) => this.touchEnd(e)));
        this.handlers.push(on.listen(this.mouseUpTarget, "touchmove", (e: TouchEvent) => this.touchMove(e)));

        this.handlers.push(on.listen(this.mouseTarget, "click", (e: MouseEvent) => this.mouseClick(e)));
        this.handlers.push(on.listen(this.mouseTarget, "dblclick", (e: MouseEvent) => this.mouseDoubleClick(e)));
        this.handlers.push(on.listen(this.contextMenuTarget, "contextmenu", (e: MouseEvent) => this.contextMenu(e)));
    }

    public disable(): void {
        // clear handlers
        let f = null;
        while ((f = this.handlers.pop())) {
            f();
        }
    }

    public keyUp(event: KeyboardEvent): void {
        if (this.areKeyboardEventsEnabled) {
            const key = event.ctrlKey ? "CTRL-" + event.key : event.key;
            if (this.allowedKeys.indexOf(key) === -1) {
                event.preventDefault();
            }
            this.delegateEvent({
                type: InputManagerEvent.KEYUP,
                value: event,
            } as AppEvent);
        }
    }

    public keyDown(event: KeyboardEvent): void {
        if (this.areKeyboardEventsEnabled) {
            const key = event.ctrlKey ? "CTRL-" + event.key : event.key;
            if (this.allowedKeys.indexOf(key) === -1) {
                event.preventDefault();
            }
            this.delegateEvent({
                type: InputManagerEvent.KEYDOWN,
                value: event,
            } as AppEvent);
        }
    }

    public mouseDown(event: MouseEvent): void {
        event.preventDefault();

        if (this.dragging === false) {
            this.dragging = true;

            this.dragData.start.copy(this.mouse);
            this.dragData.delta.set(0, 0);
            this.dragData.end.copy(this.dragData.start);
            this.dragData.buttons = event.buttons;
            this.dragData.isStart = true;

            this.delegateEvent({
                type: InputManagerEvent.DRAG,
                value: this.dragData,
            } as AppEvent);
        }

        this.delegateEvent({
            type: InputManagerEvent.MOUSEDOWN,
            value: event,
        } as AppEvent);
    }

    public mouseMove(event: MouseEvent): void {
        event.preventDefault();

        // get mouse position
        const rect = this.mouseTarget === window ? null : this.mouseTarget.getBoundingClientRect();
        const x = event.clientX - (rect == null ? 0 : rect.left);
        const y = event.clientY - (rect == null ? 0 : rect.top);
        this.mouse.set(x, y);

        if (this.dragging) {
            this.dragData.delta.subVectors(this.mouse, this.dragData.end);
            // send only if mouse has been moved by at least one pixel
            if (this.dragData.delta.manhattanLength() >= 1) {
                this.dragData.end.copy(this.mouse);
                this.dragData.isStart = false;

                this.delegateEvent({
                    type: InputManagerEvent.DRAG,
                    value: this.dragData,
                } as AppEvent);
            }
        }

        this.delegateEvent({
            type: InputManagerEvent.MOUSEMOVE,
            value: this.mouse,
        } as AppEvent);
    }

    public mouseOut(event: MouseEvent): void {
        if (event.target == this.mouseUpTarget) {
            this.mouseUp(event);
        }
    }

    public mouseUp(event: MouseEvent): void {
        event.preventDefault();

        if (this.dragging) {
            this.dragging = false;
            this.dragData.isStart = false;

            if (this.buffer.subVectors(this.dragData.end, this.dragData.start).length() <= this.clickThreshold) {
                this.delegateEvent({
                    type: InputManagerEvent.CLICK,
                    value: {
                        point: this.dragData.start,
                        buttons: this.dragData.buttons,
                    } as ClickData,
                } as AppEvent);
            }

            this.delegateEvent({
                type: InputManagerEvent.DROP,
                value: this.dragData,
            } as AppEvent);
        }

        this.delegateEvent({
            type: InputManagerEvent.MOUSEUP,
            value: event,
        } as AppEvent);
    }

    public mouseClick(event: MouseEvent): void {
        event.preventDefault();
        this.delegateEvent({
            type: InputManagerEvent.MOUSECLICK,
            value: this.mouse,
        } as AppEvent);
    }

    public mouseDoubleClick(event: MouseEvent): void {
        event.preventDefault();
        this.delegateEvent({
            type: InputManagerEvent.DOUBLECLICK,
            value: this.mouse,
        } as AppEvent);
    }

    public mouseWheel(e: WheelEvent): void {
        e.preventDefault();
        // normalized using sign
        this.delegateEvent({
            type: InputManagerEvent.MOUSEWHEEL,
            value: {
                delta: Math.sign(e.deltaY !== undefined ? e.deltaY : e.detail !== undefined ? -e.detail : 0),
                origin: e,
            } as WheelData,
        } as AppEvent);
    }

    public contextMenu(event: MouseEvent): void {
        event.preventDefault();
        this.delegateEvent({
            type: InputManagerEvent.CONTEXTMENU,
            value: event,
        } as AppEvent);
    }

    public touchStart(event: TouchEvent): void {
        event.preventDefault();

        if (!this.dragging) {
            this.dragging = true;

            const rect = this.mouseUpTarget === window ? null : this.mouseUpTarget.getBoundingClientRect();
            const x = event.touches[0].pageX - (rect == null ? 0 : rect.left);
            const y = event.touches[0].pageY - (rect == null ? 0 : rect.top);
            this.mouse.set(x, y);

            this.dragData.start.copy(this.mouse);
            this.dragData.delta.set(0, 0);
            this.dragData.end.copy(this.dragData.start);
            this.dragData.buttons = 1;

            this.delegateEvent({
                type: InputManagerEvent.DRAG,
                value: this.dragData,
            } as AppEvent);
        }

        this.delegateEvent({
            type: InputManagerEvent.TOUCHSTART,
            value: event,
        } as AppEvent);
    }

    public touchMove(event: TouchEvent): void {
        event.preventDefault();

        if (event.touches.length == 1) {
            const rect = this.mouseUpTarget === window ? null : this.mouseUpTarget.getBoundingClientRect();
            const x = event.touches[0].pageX - (rect == null ? 0 : rect.left);
            const y = event.touches[0].pageY - (rect == null ? 0 : rect.top);
            this.mouse.set(x, y);

            if (this.dragging) {
                this.dragData.delta.subVectors(this.mouse, this.dragData.end);
                this.dragData.end.copy(this.mouse);

                this.delegateEvent({
                    type: InputManagerEvent.DRAG,
                    value: this.dragData,
                } as AppEvent);
            }
        }

        this.delegateEvent({
            type: InputManagerEvent.TOUCHMOVE,
            value: event,
        } as AppEvent);
    }

    public touchEnd(event: TouchEvent): void {
        event.preventDefault();

        if (this.dragging) {
            this.dragging = false;

            this.delegateEvent({
                type: InputManagerEvent.DROP,
                value: this.dragData,
            } as AppEvent);
        }

        this.delegateEvent({
            type: InputManagerEvent.TOUCHEND,
            value: event,
        } as AppEvent);
    }
}
